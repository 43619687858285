import Gallery from "../../Components/Gallery/Gallery";
import ImageDictionary from "../../ImageDictionary";
import "./GalleryPage.scss";

const GalleryPage = () => {
  return (
    <div>
      <Gallery images={ImageDictionary} />
    </div>
  );
};

export default GalleryPage;
