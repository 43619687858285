import React, { useEffect } from "react";

const AppLoadingPlaceHolder = () => {
  return (
    <div className="page-wrapper">
      <img src={"/Assets/loopimg.gif"} className="main-background" />

      <div className="page-overlay">
        <div className="mid-element">
          <div className="label-zone">
            <img src={"/Assets/mionmir-logo.png"}></img>
            <div>
              Il sito ufficiale di Mion'Mir è in arrivo su questo dominio.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLoadingPlaceHolder;
