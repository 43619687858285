import AppLoadingPlaceHolder from "../../AppLoadingPlaceHolder";
import "./HomePage.scss";

const HomePage = () => {
  return (
    <div>
      <AppLoadingPlaceHolder />
    </div>
  );
};

export default HomePage;
