import "./LorePage.scss";
import { useEffect, useState } from "react";

import AudioUtil from "../../Utils/audioUtil";
import LoreSubPage from "./LoreSubPage";
import {
  IContentBlock,
  ILoreTextWall,
  LoreTextWalls,
} from "../../TextWalls/LoreTextWalls";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import Loadable from "../../Components/Loadable/Loadable";

export interface ILoreSection {
  title: string;
  shortDescription: string;
  imagePath?: string;
  innerPages?: ILoreSection[];
  content?: IContentBlock[];
}

const LoreMenu = (props: {
  section: ILoreSection;
  onSelected: (self: ILoreSection) => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<ILoreSection>(props.section);
  const isChildNode = (data.innerPages ?? []).length === 0;

  useEffect(() => {
    setData(data);
  }, [data]);

  let wrapperStyle: any = {};
  if (isOpen && !isChildNode) {
    wrapperStyle.paddingBottom = "0.5em";
  }
  if (data.imagePath) {
    wrapperStyle.background = "url('" + data.imagePath + "')";
  }

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="lore-menu-main-wrap" style={wrapperStyle}>
      <div
        className="lore-menu-banner"
        onClick={() => {
          AudioUtil.PlayAudio("mouseclick.wav");
          if (!isChildNode) {
            setIsOpen(!isOpen);
          } else {
            props.onSelected(data);
          }
        }}
      >
        <div className="lore-menu-title-zone">
          {(data.innerPages ?? []).length > 0 && (
            <div className="lore-menu-chevron-wrap">
              <div
                className={
                  "lore-menu-chevron-symbol " +
                  (isOpen ? "lore-menu-chevron-symbol-open" : "")
                }
              >
                ›
              </div>
            </div>
          )}
          <div>
            <div className="lore-menu-title">{data.title}</div>
            <div className="lore-menu-content">{data.shortDescription}</div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="lore-menu-inner-pages">
          {data.innerPages?.map((page: ILoreSection, index: number) => {
            return (
              <div key={index}>
                <LoreMenu section={page} onSelected={props.onSelected} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const LorePage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lorepage: string = searchParams.get("page") ?? "";
  const [loreSections, setLoreSections] = useState<ILoreSection[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedSection, setSelectedSection] = useState<
    ILoreSection | undefined
  >();

  const TextWallSectionToInnerPage = (walls: ILoreTextWall[]) => {
    let loreInnerPages: ILoreSection[] = [];

    walls.forEach((y: ILoreTextWall) => {
      let newItem = {
        title: y.title,
        shortDescription: y.shortDescription,
        imagePath: y.imagePath,
        content: y.content,
        innerPages: TextWallSectionToInnerPage(y.sections ?? []),
      };

      if (newItem.title === lorepage) {
        setSelectedSection(newItem);
      }

      loreInnerPages.push(newItem);
    });

    return loreInnerPages;
  };

  useEffect(() => {
    setLoading(true);
    setSelectedSection(undefined);
    const LoreSections: ILoreSection[] =
      TextWallSectionToInnerPage(LoreTextWalls);

    setLoreSections(LoreSections);

    setTimeout(() => {
      setLoading(false);
    }, 1500 + Math.random() * 1000);
  }, [lorepage]);

  return (
    <div className="lore-page-main-wrap">
      <div className="page-main-title">
        <div className="separator-holder">
          <img src={"/Assets/separator.svg"} alt="separator" />
        </div>
        <div>Lore</div>
        <div className="separator-holder">
          <img src={"/Assets/separator.svg"} alt="separator" />
        </div>
      </div>
      <Loadable
        loaderStyle={{ paddingTop: "5em" }}
        isLoading={loading}
        content={
          <div>
            {!lorepage && (
              <div className="lore-section-main-wrap">
                {loreSections.map((section: ILoreSection, index: number) => {
                  return (
                    <div key={index}>
                      <LoreMenu
                        section={section}
                        onSelected={(self: ILoreSection) => {
                          navigate(
                            AppRoutes.LORE_PAGE +
                              "?page=" +
                              encodeURI(self.title)
                          );
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {selectedSection && (
              <LoreSubPage
                onBack={() => {
                  navigate(AppRoutes.LORE_PAGE);
                }}
                title={selectedSection.title}
                content={selectedSection.content ?? []}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default LorePage;
