import { useEffect, useState } from "react";
import { IImageDictionary } from "../../ImageDictionary";
import ImageDisplayer from "../ImageDisplayer/ImageDisplayer";
import "./Gallery.scss";
import AudioUtil from "../../Utils/audioUtil";
import Loadable from "../Loadable/Loadable";

interface IGalleryProps {
  images: IImageDictionary[];
}

var evidentImageIndexClone = -1;
const Gallery = (props: IGalleryProps) => {
  const [searchText, setSearchText] = useState<string>("");
  const [evidentImageIndex, setEvidentImageIndex_] = useState<number>(-1);
  const [canonOnly, setCanonOnly] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);

  const doReload = () => {
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1500 + Math.random() * 1000);
  };

  const setEvidentImageIndex = (index: number) => {
    setEvidentImageIndex_(index);
    evidentImageIndexClone = index;
  };

  const validImageForSearch = (image: IImageDictionary) => {
    let titleIsMatch = image.imageTitle
      .toLowerCase()
      .includes(searchText.toLowerCase());
    let styleIsMatch = image.imageStyle
      .toLowerCase()
      .includes(searchText.toLowerCase());
    let metadataIsMatch = image.imageMetadata
      .toLowerCase()
      .includes(searchText.toLowerCase());
    return titleIsMatch || styleIsMatch || metadataIsMatch;
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "ArrowLeft" && evidentImageIndexClone > 0) {
      setEvidentImageIndex(evidentImageIndexClone - 1);
    }
    if (
      event.key === "ArrowRight" &&
      evidentImageIndexClone < props.images.length - 1
    ) {
      setEvidentImageIndex(evidentImageIndexClone + 1);
    }
    if (event.key === "Escape") {
      setEvidentImageIndex(-1);
    }
  };

  useEffect(() => {
    doReload();
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className="gallery-main-wrap">
      <div className="gallery-search-bar-wrapper">
        <div className="canon-switch-button">
          <input
            defaultChecked={canonOnly}
            type="checkbox"
            id="my-toggle"
            onChange={(e: any) => {
              doReload();
              setCanonOnly(e.target.checked);
            }}
          />
          <label htmlFor="my-toggle">solo canonici</label>
        </div>
        <input
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          className="gallery-search-bar"
          type="search"
          placeholder="Cerca personaggi, oggetti o lore..."
        />
      </div>
      <Loadable
        loaderStyle={{ paddingTop: "5em" }}
        isLoading={reload}
        content={
          <div>
            <div className="gallery-image-section">
              {[...props.images]
                .filter((x: IImageDictionary) => {
                  return (
                    validImageForSearch(x) &&
                    (!canonOnly || x.isCanonForCharacter)
                  );
                })
                .map((image: IImageDictionary, index: number) => {
                  return (
                    <div
                      key={index}
                      className="image-displayer-wrap"
                      style={reload ? { opacity: "0" } : {}}
                    >
                      <ImageDisplayer
                        image={image}
                        onSetEvident={(evident: boolean) => {
                          AudioUtil.PlayAudio("mouseclick.wav");

                          if (evident) {
                            setEvidentImageIndex(index);
                          } else {
                            setEvidentImageIndex(-1);
                          }
                        }}
                        isEvident={index === evidentImageIndex}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Gallery;
