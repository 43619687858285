import { ILoreTextWall } from "./LoreTextWalls";

export const WALL: ILoreTextWall = {
  title: "Religione",
  shortDescription: "Le religioni di questo mondo",
  imagePath: "/assets/chapel.png",
  sections: [
    {
      title: "Culto dei sette",
      shortDescription: "I sette dei della luce, figli di Askiaa",
      content: [
        {
          type: "title",
          value: "Il culto dei sette, la religione della luce",
        },
        {
          type: "text",
          value:
            "Il culto dei sette nasce a seguito delle incessanti predicazioni del suo più grande profeta, Opsei. " +
            "Non si sa molto di lui, se non che era un uomo di grande saggezza e che riuscì a unire i popoli del Gunebar sotto un'unica bandiera religiosa. " +
            "Il culto dei sette è una religione politeista che venera sette divinità, figlie di Askiaa, il dio della luce. " +
            "Ogni divinità rappresenta un aspetto della vita e della natura, e i fedeli sono chiamati a seguire i precetti di ognuna di esse. " +
            "Il culto dei sette è una religione pacifista che predica la pace e la fratellanza tra i popoli. " +
            "I templi dei sette sono luoghi di preghiera e riflessione, dove i fedeli si riuniscono per pregare e meditare, chiamati Sinjid. " +
            "Molto diffuso nel Gunebar fin dai primi secoli della terza era, ha poi trovato seguaci anche in Fedora, dove è stato adottato come religione di stato di vari regni di Fedora a partire dal 1300 della terza era circa. " +
            "Le sette divinità che compongono il pantheon dei sette sono: ",
        },
        {
          type: "jsx",
          value: (
            <ul>
              <li>
                Arzik: il portatore di fortuna e forgiatore di intelligenza,
                protettore degli artisti
              </li>
              <li>
                Hurna: custode della rinascita e della guarigione, protettrice
                delle madri
              </li>
              <li>
                Querrell: pacificatrice delle controversie e ispiratrice di
                clemenza, protettrice degli orfani
              </li>
              <li>
                Tolkanigan: il guardiano delle forze della natura, protettore
                degli animali
              </li>
              <li>
                Shelmalakia: rivelatrice di segreti e liberatrice degli
                ingannati, protettrice dei cercatori
              </li>
              <li>
                Lanthamos: Signore dell'eredità e della tradizione, protettore
                degli innocenti
              </li>
              <li>
                Khilmitaur: camminatore di sogni e protettore dei folli e dei
                disabili di ogni tipo
              </li>
            </ul>
          ),
        },
        {
          type: "text",
          value:
            "Il culto dei sette prevede una gerarchia di sacerdoti che si divide su quattro livelli principali: i novizi, i sacerdoti, gli illustri e i sommi sacerdoti. " +
            "I novizi sono coloro che si stanno preparando a diventare sacerdoti, i sacerdoti sono coloro che hanno ricevuto l'ordinazione e che si occupano della gestione dei templi, " +
            "i patrocinatori sono coloro che si occupano di diffondere la fede e di aiutare i fedeli, oltre a gestire gli aspetti istituzionali del culto. " +
            "I sommi sacerdoti sono solo sette, e tutti si trovano nello stesso luogo, il tempio situato nella cupola nel cielo, una struttura dei primogeniniti sopravvissuta ai grandi cataclismi. " +
            "Inizialmente un osservatorio, fu trasformato in tempio dai primi sacerdoti del culto dei sette. In quel luogo i sette sommi sacerdoti, ciascuno in diretta adorazione di una diversa divinità, " +
            "regolano il rapporto del divino con il resto dei fedeli. Questi sommi sacerdoti vengono eletti solo quando uno di essi perisce, il che accade raramente, poichè la vicinanza al divino gli garantisce " +
            "una vita di circa tre secoli. Quando uno di loro muore, i sacerdoti di tutto il mondo si riuniscono per eleggere il nuovo sommo sacerdote. " +
            "Quando l'elezione è completa, ci si reca nella città di Sidurn, nel regno di Entmina, nell'Olongea, dove si trova il tempio di Dibeia, il tempio più sacro del culto dei sette, esclusa ovviamente la cupola nel cielo. " +
            "Li avviene il rituale di ascensione e il nuovo sommo sacerdote è assunto nella cupola dove presterà servizio per il resto dei suoi giorni.",
        },
        {
          type: "text",
          value:
            "Con il crescere della forza di questa fede, giunse la necessità di fondare un organo terreno che gestisse gli aspetti politici e burocratici del culto. Nacque così la corte dei sette, un organo " +
            "che si occupa di gestire le relazioni diplomatiche tra i vari regni e di garantire la pace e la stabilità tra i fedeli. La corte dei sette è composta numerose persone di rilievo e spesso consacrati di vario calibro. " +
            "La corte dispone di vari poteri, tra cui quelli di inviare in missione i membri della sua inquisizione, che spesso sono legati ad azioni di spionaggio e di controllo dei fedeli, ma a volte agiscono anche come operatori sul campo degli interessi del culto. ",
        },
      ],
    },
    {
      title: "Culto cardico",
      shortDescription: "Il culto del profeta Xumaria e la missione cardica",
      content: [
        {
          type: "title",
          value: "Il culto cardico, la religione del profeta Xumaria",
        },
        {
          type: "text",
          value:
            "Il culto cardico nasce nel 2541 nella città di Cardia, nei confini di quello che in futuro divverrà il territorio di Migulma, come risposta alla crescente povertà e disperazione che affliggeva la popolazione. " +
            "Il profeta Xumaria, un uomo di umili origini, iniziò a predicare la parola di un dio sconosciuto, che chiamava il Dio della Misericordia. " +
            "Egli disse di aver ricevuto una rivelazione divina, e scrisse un libro sacro, il Rithium, in cui raccolse le parole del dio. " +
            "Il culto cardico è una religione monoteista che predica la compassione e la carità, e che si oppone alla violenza e all'ingiustizia. " +
            "I templi cardici che fondarono a seguito del suo success sono luoghi di accoglienza e di assistenza per i poveri e gli emarginati, e i sacerdoti di questa fede sono chiamati ad occuparsi di loro. " +
            "Lo scopo del culto era diffondere la parola e la missione di Xumaria, e portare la luce del Dio della Misericordia a tutti i popoli di Fedora. " +
            "Il culto cardico si diffuse nei secoli successivi grazie all'azione dei missionari, che portarono la parola di Xumaria in ogni angolo del continente. " +
            "Ciò che diede vera forza al culto era la lunga lista di miracoli attribuiti al profeta, che lo resero una figura venerata in tutto il mondo. ",
        },
        {
          type: "text",
          value:
            "Oggi il culto cardico è una delle religioni più diffuse di Fedora, e conta milioni di fedeli in tutto il mondo. " +
            "I sacerdoti cardici sono chiamati a seguire i precetti del Rithium, e a diffondere la parola del Dio della Misericordia tra i popoli di Fedora. " +
            "Il culto non prevede una gerarchia sacerdotale, ma si basa su una comunità di fedeli che si riuniscono per pregare e meditare insieme. " +
            "Prevede però la figura dei monaci, persone che sacrificano la loro vita per seguire la via del Dio della Misericordia e aiutare i più bisognosi. " +
            "I monaci cardici vivono in piccoli conventi, e si dedicano alla preghiera e alla meditazione, e assolvono ai rituali previsti dal culto. ",
        },
        {
          type: "text",
          value:
            "Il culto prevedeva una guerra spirituale alle forze oscure tramite una vita casta e virtuosa, e il suo profeta anticipava il compimento di tre promesse divine che si sarebbero verificate prima del giorno del giudizio. " +
            "Tre eventi tremendi che avrebbero dovuto scuotere le coscense dei fedeli per prepararsi agli ultimi tempi. " +
            "Una grande guerra che ridurrà il mondo in povertà, poi una notte perenne che avrebbe avvolto la terra nelle tenebre per molti giorni e infine, " +
            "il ritorno di una bestia. Un mostro antico che avrebbe portato la distruzione ovunque, consumando il mondo. " +
            "La volontà del dio della misericordia era che i fedeli si preparassero a questi eventi, e che moltiplicassero le opere di bene per ridurre le forze della bestia, che " +
            "avrebbe potuto essere fermata solo con la forza della compassione e della carità. Un ultimo sforzo di bene prima della fine del mondo. ",
        },
      ],
    },
    {
      title: "Culto shamanico",
      shortDescription: "Il culto del potere del grande spirito",
      content: [
        {
          type: "title",
          value: "Il culto shamanico, la religione del grande spirito",
        },
        {
          type: "text",
          value:
            "Il culto shamanico è un culto antico che risale ai tempi della seconda era, quando i popoli di Fedora vivevano in armonia con la natura e con gli spiriti degli antenati. " +
            "I sacerdoti di questa fede, chiamati sciamani, erano considerati intermediari tra il mondo degli uomini e il mondo degli spiriti, e si occupavano di preservare l'equilibrio tra i due. " +
            "Il culto shamanico è una religione animista che venera il grande spirito, una divinità che si crede abiti in ogni cosa vivente e inanimata. " +
            "I templi shamanici sono luoghi di preghiera e di meditazione, dove i fedeli si riuniscono per onorare il grande spirito e per chiedere la sua protezione. " +
            "Il culto shamanico è una religione pacifista che predica l'armonia tra gli esseri viventi e la natura, e che si oppone alla violenza e all'ingiustizia. " +
            "I sacerdoti di questa fede sono chiamati a seguire i precetti del grande spirito, e a diffondere la sua parola tra i popoli di Fedora. " +
            "Il culto shamanico è una delle religioni più antiche di Fedora, ma ormai è in declino e conta poche decine di migliaia di praticanti in tutto il mondo. ",
        },
      ],
    },
    {
      title: "Culto oscuro",
      shortDescription:
        "I culti dei tre dei delle tenebre, figli del Trementor",
      content: [
        {
          type: "title",
          value: "Il culto oscuro, la religione delle tenebre",
        },
        {
          type: "text",
          value:
            "Il culto oscuro non è una vera e propria religione. Si tratta della pratica perversa di cercare il contatto con divinità oscure, figlie del Trementor, spesso chiamate anche i suoi demoni. " +
            "I tre demoni maggiori del culto sono Urhari, Mamellon e Enthial. A questi si aggiungono esseri spirituali minori che hanno scelto la via della tenebra inseguendo i tre demoni maggiori. " +
            "Il culto oscuro è una pratica pericolosa e dannosa, che porta alla corruzione dell'anima e alla distruzione della mente. " +
            "I cultisti oscuri sono individui malvagi e senza scrupoli, che cercano il potere e la ricchezza a scapito degli altri. " +
            "I templi oscuri sono luoghi di depravazione e di violenza, dove i cultisti si riuniscono per compiere riti sacrileghi e per invocare le divinità delle tenebre. " +
            "Il culto oscuro è una minaccia per l'ordine e la pace di Fedora, e i sacerdoti di questa fede sono considerati nemici della società. ",
        },
        {
          type: "text",
          value:
            "Il culto oscuro ha prodotto molti orrori e molte aberrazioni nella storia. Tra le più significative mostruosità che ha partorito si annoverano le streghe e i lich, esseri dannati e oscuri che si sono legati per sempre a una forza del male. " +
            "Questi culti oscuri sono da sempre nel mirino dei fedeli del culto dei sette, e questi si adoperano incessantemente per individuare questi nemici della luce e sterminarli. " +
            "La gilda dei purificatori nasce proprio con lo scopo di combattere i cultisti oscuri e di proteggere i popoli di Fedora dalla loro malvagità. ",
        },
      ],
    },
    {
      title: "Culto dei fenili",
      shortDescription: "Il culto delle divinità minori dei fenili",
      content: [
        {
          type: "title",
          value: "Il culto dei fenili, la religione delle foreste",
        },
        {
          type: "text",
          value:
            "Il culto dei Fenili è una religione politeista che venera le divinità minori, esseri mitologici che abitano le foreste e i boschi di Fedora. " +
            "A queste si aggiungono alcune divinità di puro spirito che i Fenili adorano come protettori e custodi delle loro case, e che considerano come guardiani delle anime dei loro avi. " +
            "Alcune delle divinità per loro più importanto sono Kharulla, la dea della caccia, Iskamae, il dio della natura e Warlon, il dio della guerra. ",
        },
        {
          type: "text",
          value:
            "Non ci sono molti documenti a riguardo del loro culto, ma si sa che i Fenili sono un popolo barbaro e selvaggio. I loro rituali più noti prevedono sacrifici di animali e in casi estremi anche umani." +
            "I fenili applicano varianti al loro culto in base al loro ceppo di appartenenza. Ogni clan ha delle sue peculiarità, ma tutti condividono la stessa base religiosa. " +
            "Il simbolo della presenza dei Fenili è spesso dato dalla presenza nella natura selvaggia dei loro famosi altari. Si ignora che ruolo giochino, ma Fedora ne è disseminata e quando se ne incontra uno, " +
            "l'esploratore sa di essere entrato in territorio Fenili. ",
        },
      ],
    },
  ],
};
