import { useEffect, useState } from "react";
import ArgumentChapter, {
  ICharacterStats,
} from "../../Components/ArgumentChapter/ArgumentChapter";
import { GetCanonImage } from "../../ImageDictionary";
import { Wall } from "../../TextWalls/CharactersTextWalls";
import "./CharactersPage.scss";
import Loadable from "../../Components/Loadable/Loadable";

export interface IChapterItem {
  title: string;
  content: string;
  imagePath: string;
  sectionTitle?: string;
  stats: ICharacterStats[];
  quote: string;
  type: "main" | "secondary" | "tertiary" | "antagonist";
}

const lorem =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

const CharactersList: IChapterItem[] = [
  {
    title: "Erujhan Adert",
    type: "main",
    content: Wall.ERUJHAN.join(" "),
    imagePath: GetCanonImage("erujhan"),
    quote: "La magia non esiste.",
    stats: [
      { name: "Forza", value: "4" },
      { name: "Antra", value: "4" },
      { name: "Percezione", value: "4" },
      { name: "Resistenza", value: "6" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "5" },
      { name: "Agilità", value: "6" },
      { name: "Fortuna", value: "6" },
      { name: "Caparbietà", value: "7" },
    ],
  },
  {
    title: "Laurelian Busham (Lulla)",
    type: "main",
    content: Wall.LAURELIAN.join(" "),
    imagePath: GetCanonImage("laurelian"),
    quote: "Decidete quello che vi pare, tanto faccio di testa mia.",
    stats: [
      { name: "Forza", value: "3" },
      { name: "Antra", value: "3" },
      { name: "Percezione", value: "5" },
      { name: "Resistenza", value: "4" },
      { name: "Carisma", value: "5" },
      { name: "Intelligenza", value: "6" },
      { name: "Agilità", value: "4" },
      { name: "Fortuna", value: "3" },
      { name: "Caparbietà", value: "6" },
    ],
  },
  {
    title: "Capitano Miguel",
    type: "main",
    content: Wall.MIGUEL.join(" "),
    imagePath: GetCanonImage("miguel"),
    quote: "Detesto arrivare per secondo.",
    stats: [
      { name: "Forza", value: "5" },
      { name: "Antra", value: "6" },
      { name: "Percezione", value: "4" },
      { name: "Resistenza", value: "5" },
      { name: "Carisma", value: "6" },
      { name: "Intelligenza", value: "5" },
      { name: "Agilità", value: "6" },
      { name: "Fortuna", value: "5" },
      { name: "Caparbietà", value: "7" },
    ],
  },
  {
    title: "Olar",
    type: "main",
    content: Wall.OLAR.join(" "),
    imagePath: GetCanonImage("olar"),
    quote: "...",
    stats: [
      { name: "Forza", value: "7" },
      { name: "Antra", value: "1" },
      { name: "Percezione", value: "3" },
      { name: "Resistenza", value: "8" },
      { name: "Carisma", value: "3" },
      { name: "Intelligenza", value: "3" },
      { name: "Agilità", value: "3" },
      { name: "Fortuna", value: "2" },
      { name: "Caparbietà", value: "2" },
    ],
  },
  {
    title: "Erida Higgins",
    type: "main",
    content: Wall.ERIDA.join(" "),
    imagePath: GetCanonImage("erida"),
    quote: "Potere della mente.",
    stats: [
      { name: "Forza", value: "3" },
      { name: "Antra", value: "5" },
      { name: "Percezione", value: "5" },
      { name: "Resistenza", value: "4" },
      { name: "Carisma", value: "3" },
      { name: "Intelligenza", value: "7" },
      { name: "Agilità", value: "3" },
      { name: "Fortuna", value: "3" },
      { name: "Caparbietà", value: "4" },
    ],
  },
  {
    title: "Dustel Sidorob",
    type: "main",
    content: Wall.DUSTEL.join(" "),
    imagePath: GetCanonImage("dustel"),
    quote: "Spero che tu abbia un buon motivo per distrurbarmi.",
    stats: [
      { name: "Forza", value: "4" },
      { name: "Antra", value: "4" },
      { name: "Percezione", value: "5" },
      { name: "Resistenza", value: "4" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "5" },
      { name: "Agilità", value: "6" },
      { name: "Fortuna", value: "4" },
      { name: "Caparbietà", value: "5" },
    ],
  },

  {
    title: "Larion Ramnisk",
    type: "secondary",
    content: Wall.LARION.join(" "),
    imagePath: GetCanonImage("larion"),
    quote: "Solo la scienza e cultura.",
    stats: [
      { name: "Forza", value: "2" },
      { name: "Antra", value: "3" },
      { name: "Percezione", value: "5" },
      { name: "Resistenza", value: "4" },
      { name: "Carisma", value: "5" },
      { name: "Intelligenza", value: "6" },
      { name: "Agilità", value: "2" },
      { name: "Fortuna", value: "5" },
      { name: "Caparbietà", value: "4" },
    ],
  },
  {
    title: "Hiuan Uruua",
    type: "secondary",
    content: Wall.HIUAN.join(" "),
    imagePath: GetCanonImage("hiuan"),
    quote: "Doma la bestia che c'è in te.",
    stats: [
      { name: "Forza", value: "5" },
      { name: "Antra", value: "6" },
      { name: "Percezione", value: "7" },
      { name: "Resistenza", value: "6" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "4" },
      { name: "Agilità", value: "5" },
      { name: "Fortuna", value: "3" },
      { name: "Caparbietà", value: "5" },
    ],
  },
  {
    title: "Eden Larse",
    type: "secondary",
    content: Wall.EDEN.join(" "),
    imagePath: GetCanonImage("eden"),
    quote:
      "Per eliminare il potere dobbiamo metterlo in condizione di bramare la sua stessa fine.",
    stats: [
      { name: "Forza", value: "4" },
      { name: "Antra", value: "3" },
      { name: "Percezione", value: "7" },
      { name: "Resistenza", value: "4" },
      { name: "Carisma", value: "7" },
      { name: "Intelligenza", value: "5" },
      { name: "Agilità", value: "4" },
      { name: "Fortuna", value: "4" },
      { name: "Caparbietà", value: "4" },
    ],
  },
  {
    title: "Hoku & Hanteki",
    type: "secondary",
    content: Wall.HOKU_HANTEKI.join(" "),
    imagePath: GetCanonImage("hanteki,hoku"),
    quote: "",
    stats: [
      { name: "Forza", value: "4 | 3" },
      { name: "Antra", value: "2 | 4" },
      { name: "Percezione", value: "8 | 8" },
      { name: "Resistenza", value: "3 | 6" },
      { name: "Carisma", value: "7 | 2" },
      { name: "Intelligenza", value: "3 | 1" },
      { name: "Agilità", value: "8 | 9" },
      { name: "Fortuna", value: "9 | 6" },
      { name: "Caparbietà", value: "2 | 1" },
    ],
  },
  {
    title: "Sharnel Pitò",
    type: "secondary",
    content: Wall.SHARNEL.join(" "),
    imagePath: GetCanonImage("sharnel"),
    quote:
      "Non esistono situazioni complicate, solo una cattiva organizzazione.",
    stats: [
      { name: "Forza", value: "3" },
      { name: "Antra", value: "3" },
      { name: "Percezione", value: "6" },
      { name: "Resistenza", value: "3" },
      { name: "Carisma", value: "9" },
      { name: "Intelligenza", value: "5" },
      { name: "Agilità", value: "3" },
      { name: "Fortuna", value: "5" },
      { name: "Caparbietà", value: "6" },
    ],
  },

  {
    title: "Naro Quins",
    type: "secondary",
    content: Wall.NARO.join(" "),
    imagePath: GetCanonImage("naro"),
    quote: "Suppongo che tu abbia bisogno di un aiuto.",
    stats: [
      { name: "Forza", value: "3" },
      { name: "Antra", value: "3" },
      { name: "Percezione", value: "4" },
      { name: "Resistenza", value: "3" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "9" },
      { name: "Agilità", value: "3" },
      { name: "Fortuna", value: "4" },
      { name: "Caparbietà", value: "6" },
    ],
  },
  {
    title: "Lotis & Pascale",
    type: "secondary",
    content: Wall.LOTIS_AND_PASCALE.join(" "),
    imagePath: GetCanonImage("lotis,pascale"),
    quote: "Chiamalo subito e digli che il prezzo è raddoppiato.",
    stats: [
      { name: "Forza", value: "3 | 2" },
      { name: "Antra", value: "2 | 4" },
      { name: "Percezione", value: "3 | 4" },
      { name: "Resistenza", value: "3 | 3" },
      { name: "Carisma", value: "5 | 4" },
      { name: "Intelligenza", value: "6 | 4" },
      { name: "Agilità", value: "1 | 3" },
      { name: "Fortuna", value: "3 | 5" },
      { name: "Caparbietà", value: "9 | 3" },
    ],
  },
  {
    title: "Beren (Erida)",
    type: "secondary",
    content: Wall.BEREN.join(" "),
    imagePath: GetCanonImage("beren"),
    quote:
      "Mi considerano un'ombra, ma presto si renderanno conto di quanto io possa essere reale.",
    stats: [
      { name: "Forza", value: "2" },
      { name: "Antra", value: "3" },
      { name: "Percezione", value: "7" },
      { name: "Resistenza", value: "4" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "fuori scala" },
      { name: "Agilità", value: "4" },
      { name: "Fortuna", value: "2" },
      { name: "Caparbietà", value: "9" },
    ],
  },

  {
    title: "Selene",
    type: "secondary",
    content: Wall.SELENE.join(" "),
    imagePath: GetCanonImage("selene"),
    quote: "Non disprezzare mai una vita, compresa la tua. È un dono prezioso.",
    stats: [
      { name: "Forza", value: "3" },
      { name: "Antra", value: "4" },
      { name: "Percezione", value: "6" },
      { name: "Resistenza", value: "3" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "5" },
      { name: "Agilità", value: "5" },
      { name: "Fortuna", value: "6" },
      { name: "Caparbietà", value: "3" },
    ],
  },
  {
    title: "Capitano Oberon",
    type: "antagonist",
    content: Wall.CAPITANO_OBERON.join(" "),
    imagePath: GetCanonImage("oberon"),
    quote: "Devono imparare a rispettare la mia autorità.",
    stats: [
      { name: "Forza", value: "4" },
      { name: "Antra", value: "5" },
      { name: "Percezione", value: "4" },
      { name: "Resistenza", value: "4" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "6" },
      { name: "Agilità", value: "5" },
      { name: "Fortuna", value: "3" },
      { name: "Caparbietà", value: "5" },
    ],
  },
  {
    title: "Comandante Lane",
    type: "antagonist",
    content: Wall.COMANDANTE_LANE.join(" "),
    imagePath: GetCanonImage("lane"),
    quote: "Muoversi, muoversi, muoversi!",
    stats: [
      { name: "Forza", value: "6" },
      { name: "Antra", value: "3" },
      { name: "Percezione", value: "3" },
      { name: "Resistenza", value: "5" },
      { name: "Carisma", value: "2" },
      { name: "Intelligenza", value: "4" },
      { name: "Agilità", value: "5" },
      { name: "Fortuna", value: "4" },
      { name: "Caparbietà", value: "7" },
    ],
  },
  {
    title: "Ravel Shnaide",
    type: "antagonist",
    content: Wall.RAVEL.join(" "),
    imagePath: GetCanonImage("ravel"),
    quote: "Dovete assolutamente unirvi a noi. Insisto.",
    stats: [
      { name: "Forza", value: "3" },
      { name: "Antra", value: "4" },
      { name: "Percezione", value: "2" },
      { name: "Resistenza", value: "3" },
      { name: "Carisma", value: "8" },
      { name: "Intelligenza", value: "4" },
      { name: "Agilità", value: "4" },
      { name: "Fortuna", value: "5" },
      { name: "Caparbietà", value: "3" },
    ],
  },
  {
    title: "Dottor F",
    type: "antagonist",
    content: Wall.DOTTOR_F.join(" "),
    imagePath: GetCanonImage("dottor f"),
    quote:
      "La scienza non pone mai questioni etiche, solo le persone lo fanno.",
    stats: [
      { name: "Forza", value: "2" },
      { name: "Antra", value: "4" },
      { name: "Percezione", value: "2" },
      { name: "Resistenza", value: "3" },
      { name: "Carisma", value: "2" },
      { name: "Intelligenza", value: "10" },
      { name: "Agilità", value: "3" },
      { name: "Fortuna", value: "3" },
      { name: "Caparbietà", value: "5" },
    ],
  },
  {
    title: "Umbri, il re lich",
    type: "antagonist",
    content: Wall.UMBRI.join(" "),
    imagePath: GetCanonImage("umbri"),
    quote: "Tutto sarà ombra. Tutto sarà morte.",
    stats: [
      { name: "Forza", value: "10" },
      { name: "Antra", value: "8" },
      { name: "Percezione", value: "4" },
      { name: "Resistenza", value: "8" },
      { name: "Carisma", value: "1" },
      { name: "Intelligenza", value: "1" },
      { name: "Agilità", value: "2" },
      { name: "Fortuna", value: "2" },
      { name: "Caparbietà", value: "3" },
    ],
  },
  {
    title: "Razenna, la strega",
    type: "antagonist",
    content: Wall.RAZENNA.join(" "),
    imagePath: GetCanonImage("razenna"),
    quote:
      "Ditemi, o viaggiatori coraggiosi, avete trovato quello che cercavate?",
    stats: [
      { name: "Forza", value: "3" },
      { name: "Antra", value: "8" },
      { name: "Percezione", value: "9" },
      { name: "Resistenza", value: "5" },
      { name: "Carisma", value: "1" },
      { name: "Intelligenza", value: "5" },
      { name: "Agilità", value: "4" },
      { name: "Fortuna", value: "4" },
      { name: "Caparbietà", value: "5" },
    ],
  },
  {
    title: "Ammiraglio Grace",
    type: "antagonist",
    content: Wall.AMMIRAGLIO_GRACE.join(" "),
    imagePath: GetCanonImage("julien"),
    quote: "Ho bisogno di un sigaro. Subito.",
    stats: [
      { name: "Forza", value: "8" },
      { name: "Antra", value: "fuori scala" },
      { name: "Percezione", value: "5" },
      { name: "Resistenza", value: "9" },
      { name: "Carisma", value: "5" },
      { name: "Intelligenza", value: "4" },
      { name: "Agilità", value: "7" },
      { name: "Fortuna", value: "8" },
      { name: "Caparbietà", value: "6" },
    ],
  },
  {
    title: "Irene Ramnisk",
    type: "tertiary",
    content: Wall.IRENE.join(" "),
    imagePath: GetCanonImage("irene"),
    quote: "Non posso pensare a tutto io, no?",
    stats: [
      { name: "Forza", value: "2" },
      { name: "Antra", value: "2" },
      { name: "Percezione", value: "4" },
      { name: "Resistenza", value: "6" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "4" },
      { name: "Agilità", value: "3" },
      { name: "Fortuna", value: "4" },
      { name: "Caparbietà", value: "5" },
    ],
  },
  {
    title: "Lucian & Mya",
    type: "tertiary",
    content: Wall.LUCIAN_AND_MYA.join(" "),
    imagePath: GetCanonImage("lucian,mya"),
    quote: "Il passato deve insegnarci a non ripetere gli stessi errori.",
    stats: [
      { name: "Forza", value: "4 | 3" },
      { name: "Antra", value: "3 | 3" },
      { name: "Percezione", value: "5 | 5" },
      { name: "Resistenza", value: "4 | 3" },
      { name: "Carisma", value: "5 | 2" },
      { name: "Intelligenza", value: "4 | 5" },
      { name: "Agilità", value: "4 | 3" },
      { name: "Fortuna", value: "5 | 6" },
      { name: "Caparbietà", value: "4 | 6" },
    ],
  },

  {
    title: "Yulia Helzeidama",
    type: "tertiary",
    content: Wall.YULIA_HELZEIDAMA.join(" "),
    imagePath: GetCanonImage("yulia"),
    quote: "Quello che impari nei libri, non lo dimentichi mai.",
    stats: [
      { name: "Forza", value: "3" },
      { name: "Antra", value: "3" },
      { name: "Percezione", value: "4" },
      { name: "Resistenza", value: "3" },
      { name: "Carisma", value: "7" },
      { name: "Intelligenza", value: "7" },
      { name: "Agilità", value: "3" },
      { name: "Fortuna", value: "4" },
      { name: "Caparbietà", value: "4" },
    ],
  },
  {
    title: "Yomu",
    type: "tertiary",
    content: Wall.YOMU.join(" "),
    imagePath: GetCanonImage("yomu"),
    quote: "Onorerò la mia famiglia.",
    stats: [
      { name: "Forza", value: "7" },
      { name: "Antra", value: "4" },
      { name: "Percezione", value: "3" },
      { name: "Resistenza", value: "6" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "3" },
      { name: "Agilità", value: "7" },
      { name: "Fortuna", value: "4" },
      { name: "Caparbietà", value: "6" },
    ],
  },

  {
    title: "Kaori & Asami",
    type: "tertiary",
    content: Wall.KAORI_ASAMI.join(" "),
    imagePath: GetCanonImage("kaori"),
    quote: "La nostra casa è dove veniamo accolti con amore.",
    stats: [
      { name: "Forza", value: "3 | 4" },
      { name: "Antra", value: "5 | 3" },
      { name: "Percezione", value: "5 | 4" },
      { name: "Resistenza", value: "5 | 6" },
      { name: "Carisma", value: "5 | 4" },
      { name: "Intelligenza", value: "4 | 5" },
      { name: "Agilità", value: "4 | 6" },
      { name: "Fortuna", value: "4 | 5" },
      { name: "Caparbietà", value: "3 | 5" },
    ],
  },
  {
    title: "Githwick & Villar",
    type: "tertiary",
    content: Wall.GITWICK_VILLAR.join(" "),
    imagePath: GetCanonImage("githwick"),
    quote: "Fare si può fare... ma prima parliamo di soldi.",
    stats: [
      { name: "Forza", value: "5 | 3" },
      { name: "Antra", value: "3 | 3" },
      { name: "Percezione", value: "5 | 3" },
      { name: "Resistenza", value: "5 | 2" },
      { name: "Carisma", value: "4 | 4" },
      { name: "Intelligenza", value: "4 | 3" },
      { name: "Agilità", value: "5 | 2" },
      { name: "Fortuna", value: "7 | 4" },
      { name: "Caparbietà", value: "4 | 1" },
    ],
  },
  {
    title: "Lilith",
    type: "tertiary",
    content: Wall.LILITH.join(" "),
    imagePath: GetCanonImage("lilith"),
    quote: "Chi ti ha parlato di me?",
    stats: [
      { name: "Forza", value: "4" },
      { name: "Antra", value: "4" },
      { name: "Percezione", value: "5" },
      { name: "Resistenza", value: "5" },
      { name: "Carisma", value: "4" },
      { name: "Intelligenza", value: "5" },
      { name: "Agilità", value: "5" },
      { name: "Fortuna", value: "7" },
      { name: "Caparbietà", value: "4" },
    ],
  },
];

const sections: { title: string; key: string }[] = [
  {
    title: "Protagonisti",
    key: "main",
  },
  {
    title: "Personaggi secondari",
    key: "secondary",
  },
  {
    title: "Personaggi antagonisti e nemici",
    key: "antagonist",
  },
  {
    title: "Personaggi terziari & Comparse",
    key: "tertiary",
  },
];

const CharactersPage = () => {
  const [reload, setReload] = useState<boolean>(true);

  const doReload = () => {
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 1500 + Math.random() * 1000);
  };

  useEffect(() => {
    doReload();
  }, []);

  return (
    <div>
      <Loadable
        isLoading={reload}
        loaderStyle={{ paddingTop: "5em" }}
        content={
          <div className="characters-list">
            {sections.map(
              (section: { title: string; key: string }, j: number) => {
                return (
                  <div key={j}>
                    <div className="page-main-title">
                      <div className="separator-holder">
                        <img src={"/Assets/separator.svg"} alt="separator" />
                      </div>
                      {section.title}
                      <div className="separator-holder">
                        <img src={"/Assets/separator.svg"} alt="separator" />
                      </div>
                    </div>
                    {CharactersList.filter(
                      (item: IChapterItem) => item.type === section.key
                    ).map((item: IChapterItem, index: number) => {
                      return (
                        <div>
                          {item.sectionTitle && (
                            <div className="page-main-title">
                              <div className="separator-holder">
                                <img
                                  src={"/Assets/separator.svg"}
                                  alt="separator"
                                />
                              </div>
                              {item.sectionTitle}
                              <div className="separator-holder">
                                <img
                                  src={"/Assets/separator.svg"}
                                  alt="separator"
                                />
                              </div>
                            </div>
                          )}
                          <div className="character-chapter-title">
                            {item.title}
                          </div>
                          <ArgumentChapter
                            key={index}
                            index={index}
                            content={item.content}
                            quote={item.quote}
                            stats={item.stats}
                            imagePath={"." + item.imagePath}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              }
            )}

            <div style={{ paddingBottom: "5em" }}></div>
          </div>
        }
      />
    </div>
  );
};

export default CharactersPage;
