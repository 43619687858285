import { useEffect, useState } from "react";
import { IContentBlock } from "../../TextWalls/LoreTextWalls";
import AudioUtil from "../../Utils/audioUtil";

export interface LoreSubPageProps {
  title: string;
  content: IContentBlock[];
  onBack: () => void;
}

export interface ILoreAccordionBlock {
  title: string;
  childNodes: IContentBlock[];
}

export interface ILoreSubPageAccordionProps {
  blocks: ILoreAccordionBlock;
  startOpen?: boolean;
}

const LoreSubPageAccordion = (props: ILoreSubPageAccordionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.startOpen ?? false);
  const [content, setContent] = useState<ILoreAccordionBlock>(props.blocks);

  useEffect(() => {
    setContent(props.blocks);
  }, [props.blocks]);

  return (
    <div>
      <div
        className={"lore-main-subpage-content-title"}
        onClick={() => {
          AudioUtil.PlayAudio("mouseclick.wav");
          setIsOpen(!isOpen);
        }}
      >
        <div className="lore-menu-chevron-wrap">
          <div
            className={
              "lore-menu-chevron-symbol " +
              (isOpen ? "lore-menu-chevron-symbol-open" : "")
            }
          >
            ›
          </div>
        </div>
        <div>{content.title}</div>
      </div>

      {isOpen && (
        <div className="lore-main-subpage-content-accordion">
          {content.childNodes.map((content: IContentBlock, index: number) => {
            return (
              <div key={index}>
                <div className={"lore-main-subpage-content-" + content.type}>
                  {content.type === "text" && (
                    <div>
                      {(content.value as string)
                        .split("\n")
                        .filter((x: string) => x.length > 0)
                        .map((x: string, i: number) => {
                          return (
                            <div className="lore-section-box" key={i}>
                              {x}
                            </div>
                          );
                        })}
                    </div>
                  )}
                  {content.type === "jsx" && (
                    <div className="lore-section-box">{content.value}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const LoreSubPage = (props: LoreSubPageProps) => {
  const [contentList, setContentList] = useState<ILoreAccordionBlock[]>([]);

  useEffect(() => {
    let aggregateData: ILoreAccordionBlock[] = [];

    props.content.forEach((content: IContentBlock) => {
      if (content.type === "title") {
        aggregateData.push({
          title: content.value as string,
          childNodes: [],
        });
      } else {
        aggregateData[aggregateData.length - 1].childNodes.push(content);
      }
    });

    setContentList(aggregateData);
  }, [props.content]);

  return (
    <div className="lore-main-subpage">
      <div className="lore-main-subpage-title">
        <div
          className="back-label"
          onClick={() => {
            AudioUtil.PlayAudio("mouseclick.wav");
            props.onBack();
          }}
        >
          Indietro
        </div>
        <div>{props.title}</div>
      </div>
      <div className="lore-main-subpage-content">
        {contentList.map((content: ILoreAccordionBlock, index: number) => {
          return (
            <LoreSubPageAccordion
              key={index}
              blocks={content}
              startOpen={contentList.length === 1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LoreSubPage;
