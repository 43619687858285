import { useEffect, useState } from "react";
import "./Loadable.scss";
import Loader from "../Loader/Loader";

export interface ILoadableProps {
  isLoading: boolean;
  content: JSX.Element;
  loaderStyle?: any;
}

const Loadable = (props: ILoadableProps) => {
  const [loading, setLoading] = useState<boolean>(props.isLoading);

  useEffect(() => {
    setLoading(props.isLoading);
  }, [props.isLoading]);

  return (
    <div>
      {loading && (
        <div className="loader-main-holder" style={props.loaderStyle}>
          <Loader />
        </div>
      )}
      {!loading && props.content}
    </div>
  );
};

export default Loadable;
