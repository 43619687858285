import { ILoreTextWall } from "./LoreTextWalls";

export const WALL: ILoreTextWall = {
  title: "Storia",
  shortDescription: "La storia di questo mondo",
  imagePath: "/assets/hist-battle.png",
  sections: [
    {
      shortDescription: "Guerra tra luce e oscurità",
      title: "Prima del tempo",
      content: [
        {
          type: "title",
          value: "La creazione",
        },
        {
          type: "text",
          value:
            "In principio esisteva solo Makmar, il dio eterno della realtà. " +
            "Makmar gioiva perfettamente della sua esistenza e in se stesso contemplava la potenza di ogni possibile mondo. " +
            "Una delle realtà da lui considerate gli apparve tanto splendida e buona, che decise di crearla per prenderla in sposa. " +
            "Nella sua mente Makmar giudicò che la sua creazione, perchè potesse essere autenticamente sua, doveva essere il risultato di un dialogo e di un atto di libero amore. " +
            "Perchè questo dialogo potesse avvenire, la creazione doveva essere libera e dotata di volontà. Prevedendo i limiti della creazione nel comprendere e usare i suoi doni, Makmar considerò necessaria la presenza di una gerarchia di potenze che pian piano educasser la creazione stessa e mediassero il dialogo con lui. " +
            "Tali potenze, dalle più alte alle più basse, dovevano cooperare alla preghiera universale della realizzazione di tutte le cose.\n\n" +
            "Così Makmar parlò e dalla sua bocca nacquero i suoi due primi figli. Questi esseri divini erano i più belli e nobili tra tutti gli esseri che ci sono stati e che mai ci saranno. " +
            "I figli maggiori di Makmar erano due, Askiaa e Tarmen. " +
            "Askiaa, angelo della vita, aveva il compito di infiltrare ogni angolo della creazione per render onnipresente l'amore di Makmar per la sua creazione. " +
            "Tarmen, angelo del tempo, aveva il compito di scandire i tempi della creazione per rispettarne la volonta, ed eventualmente sigillare nell'eternità le parti mature e perfette di essa.\n\n" +
            "La creazione dunque cominciò ad evolversi partendo dal caos primordiale, secondo il piano divino di Makmar, nel rispetto della libertà di tutte le parti della creazione stessa. " +
            "Askiaa e Tarmen erano in profonda comunione con il loro creatore, e si misero al lavoro obbedendo senza fare domande. " +
            "Più la creazione procedeva, più Askiaa e Tarmen si rendevano conto che la creazione era buona e che il loro creatore era buono. " +
            "Quando il tempo fu maturo, Makmar creò i suoi figli minori, dieci esseri simili a dei, e li pose sotto Askiaa e Tarmen perchè li aiutassero nella loro opera. " +
            "Oltre a loro, vennero creati esseri spirituali di rango sempre minore, così che ogni livello della creazione avesse chi lo governasse e lo guidasse. " +
            "Le creature su ogni piano esistenziale potevano dialogare solo con quelle dei piani adiacenti, ma tutte loro potevano guardare in alto verso Makmar e contemplarne la divinità.\n\n" +
            "Il tempo dell'universo scorreva placido, mentre le potenze della gerarchia di Makmar lavoravano per strutturare i vari piani del mondo, partendo da quelli più vicini al divino fino a quelli sempre meno spirituali, fino ad arrivare ai limiti stessi del mondo dello spirito.\n\n" +
            "Oltre alla realtà spirituale in cui vivevano gli dei, chiamata Aetherius, Makmar aveva predisposto un mondo materiale, chiamata Almorah. Un mondo che era tanto piccolo da esser rimasto inosservato per tutte le fasi precedenti della creazione. " +
            "Questo mondo era il più complesso e il più lento da creare, in quanto era soggetto a leggi che il mondo dello spirito non conosceva. " +
            "La realtà materiale, quando fu rivelata da Makmar ai suoi figli, risultò loro grottesca e incomprensibile. Askiaa e Tarmen addirittura si rifiutavano parlarne, in quanto ne detestavano la natura.\n\n" +
            "Al centro del mondo materiale, Makmar decise di porre l'essere più misterioso che avesse ideato: l'uomo. " +
            "L'uomo rappresentava la minore di tutte le potenze celesti. Una creatura tanto distante dal divino da ignorare il suo coinvolgimento in esso. " +
            "Eppure, contro ogni logica della gerarchia stessa, Makmar diede all'uomo un potere concesso solo ad Askiaa e Tarmen: la possibilità di dialogare direttamente con lui in preghiera, chiudendo il cerchio della gerarchia. Da Makmar a Makmar." +
            "Nessuna altra creatura spirituale o terrena, esclusi i figli maggiori di Makmar, aveva questo privilegio.\n\n" +
            "Inizialmente l'esistenza dell'uomo fu trascurata, questo nonostante la richiesta di Makmar di iniziare a strutturare la comunione degli spiriti anche con l'uomo. Le potenze divine vedevano la gloria di cui erano testimoni nel piano spirituale, e non avevano tempo da perdere a considerare gli uomini. " +
            "Col maturare dell'opera, divenne però chiaro agli esseri spirituali che lo scopo di tutta la gerarchia divina era quello di rendere il mistero del divino accessibile alla piccola dimensione umana. " +
            "L'uomo, per quanto piccolo e insignificante, era il centro della creazione, e la sua esistenza era il motivo per cui tutto il resto era stato creato.",
        },
        {
          type: "title",
          value: "La ribellione del dio caduto",
        },
        {
          type: "text",
          value:
            "Tarmen, angelo del tempo, era il più intelligente e il più ambizioso tra i figli di Makmar. Comprese che il processo della trasformazione della creazione non sarebbe durato in eterno. " +
            "Prima o poi, tutto sarebbe stato congelato nell'eternità, e la creazione avrebbe raggiunto la sua perfezione. " +
            "Questo voleva dire, che presto o tardi, il suo compito sarebbe stato esaurito. Preoccupato per il suo destino, Tarmen chiese al padre quale sarebbe stato il suo ruolo dopo la fine della creazione. " +
            "Spinto dall'insistenza delle domande del figlio, Makmar rivelò a Tarmen il destino finale della creazione, al che lui ne fu sconvolto. " +
            "Makmar voleva rendere l'uomo capace di accogliere l'essenza divina, e dargli il potere di governare la creazione al suo posto. Intendeva essere uno con l'uomo e renderlo la sua eterna dimora, poichè Makmar si era innamorato di ciò che l'uomo poteva essere se unito a lui. " +
            "Tarmen ne fu trafitto come da una lancia. Odiò ogni sillaba di quella profezia. Reputava che se Makmar avesse dovuto cedere il dominio sulla creazione, allora questo sarebbe dovuto passare necessariamente nelle mani sue e di Askiaa, gli esseri più simili a Makmar. " +
            "Non avrebbe mai accettato di mettere se stesso al servizio di un essere inferiore come l'uomo.\n\n" +
            "Spinto da furore, ricattò il padre, tentandolo sul suo amore. Lo obbligò a scegliere tra l'uomo e lui. Considerandosi il preferito del padre, visti i doni immensi da lui ricevuti, Tarmen si aspettava che Makmar scegliesse lui. " +
            "Invece il creatore scelse l'uomo, e Tarmen si sentì tradito. Per questo motivo, egli si ribellò apertamente al suo creatore. " +
            "Il suo cuore si riempì di odio verso il padre e ancor di più per l'uomo. Devastato, Tarmen chiamò a raccolta tutti i figli di Makmar a lui fedeli per combattere contro di lui. " +
            "Tarmen sapeva che Makmar aveva bisogno di loro per completare la creazione, e se tutti gli dei si fossero ribellati, Makmar non avrebbe potuto far altro che rinunciare al suo piano. " +
            "Tarmen si precipitò dal fratello per avvelenare il suo cuore con parole di odio verso il creatore. Raccontò ad Askiaa e ai dieci figli minori che Makmar li aveva traditi, e che pianificava di renderli eterni schiavi di esseri inferiori. " +
            "Quel destino, ai suoi occhi, era una maledizione peggiore della non-esistenza stessa. La notizia sconvolse le potenze celesti.\n\n" +
            "Tarmen era molto intelligente, e sapeva bene che anche Askiaa non reputava l'uomo degno di governare la creazione, e per questo credeva di poter convincere il fratello a combattere al suo fianco. " +
            "Accadde però qualcosa che non si aspettava. Askiaa, pur non comprendendo le ragioni della decisione del padre, decise di obbedirgli e di non combattere contro di lui. " +
            "Anzi, Askiaa provò ad aiutare Tarmen ad accettare il destino scelto dal padre, ma Tarmen si rifiutò di ascoltare e si chiuse con durezza ad ogni dialogo.\n\n" +
            "La sua collera divenne incontrollata. Sapeva che senza Askiaa non aveva il potere necessario di annullare l'opera del creatore. " +
            "Furioso, giurò che se non avesse potuto fermare la creazione, l'avrebbe almeno corrotta. Quando cercò di invaderla, però, scoprì che non poteva farlo. Il mondo materiale era protetto da una barriera invalicabile, perfino per lui. " +
            "Una creatura spirituale, anche se divina, poteva accedere al mondo materiale solo se un umano lo invocava. " +
            "Questa regola era stata creata da Makmar all'inizio del tempo, che nella sua infinita saggezza aveva voluto dare all'uomo il potere di scegliere se accogliere o meno la presenza divina. " +
            "Umiliato, decise di interrompere subito l'effetto benefico della sua grazia divina. Senza la benedizione del suo potere, pensato per fissare nell'eternità le parti perfette della creazione donandole riposo, il mondo terreno fu condannato a tribolare e faticare molto per ogni passo della sua creazione. " +
            "Tarmen, come segno di assoluto disprezzo per il piano di Makmar, si spaccò il suo cuore in due e da esso nacquero le tenebre. Quell'atto sacrilego spezzò il legame tra Tarmen e Makmar, e nella creazione entrarono inganno, ribellione e tormento. " +
            "Makmar si sdegnò per quel gesto e ribattezzò Tarmen, chiamandolo Trementor, angelo della perdizione. Il Trementor creò in se la dimensione della ribellione a Makmar, e divenne il signore di quella realtà. Il suo gesto fu così potente che la creazione stessa ne fu scossa. " +
            "Anche tre dei dieci figli minori di Makmar, essendo anche loro liberi, si unirono a lui, e divennero i principi dei demoni.\n\n" +
            "La guerra di Trementor contro Makmar era iniziata.",
        },
      ],
    },
    {
      title: "Prima era",
      shortDescription: "La prima era di questo mondo",
      content: [
        {
          type: "title",
          value: "La rivelazione divina all'uomo",
        },
        {
          type: "text",
          value:
            "Per secoli, la guerra tra Askiaa e Trementor si svolse in ogni angolo della creazione escluso il mondo terreno. " +
            "Askiaa, angelo della vita, era il più potente tra i figli di Makmar, e aveva il compito di proteggere la creazione e farla fiorire. " +
            "Trementor, angelo della perdizione, era il più astuto e il più malvagio tra i figli di Makmar, e cercava in ogni modo di distruggere il padre ferendolo nella sua amata creazione. " +
            "Askiaa, pur essendo il più potente, non poteva sconfiggere Trementor da solo. Quella guerra non avrebbe mai potuto terminare. Tutta la creazione sembrava destinata a rimanere sospesa in un conflitto eterno. " +
            "Anche i sette dei minori fedeli ad Askiaa e i tre demoni minori fedeli al Trementor si scontravano spesso, ma la loro natura divina rendeva impossibile la distruzione degli avversari. " +
            "Quello che il Trementor ignorava era che Makmar aveva predisposto un piano per la salvezza della sua creazione. " +
            "Makmar si era scelto un popolo con qualità particolari per iniziare un dialogo privilegiato rispetto a tutti gli altri popoli della terra, il popolo di Amrath. " +
            "Lavorando in segreto per vari secoli, Makmar stesso aveva donato ad alcuni uomini di quel popolo delle intuizioni sull'esistenza del piano divino, forgiando così i primi profeti.\n\n" +
            "Essendo questo dialogo al di fuori della gerarchia delle potenze celesti, la sua chiarezza era molto ridotta. Il divino risultava incomprensibile all'uomo e spesso Makmar veniva inevitabilmente frainteso. " +
            "Con il tempo però, alcuni uomini furono via via in grado di comprendere meglio il piano divino e di pregare in modo adeguato per ottenere l'aiuto delle potenze della luce. " +
            "Inizialmente le invocazioni permettevano agli uomini di entrare in contatto con le schiere minori del mondo spirituale. Esseri limitati e deboli, ma che potevano aiutare gli uomini a comprendere il mistero divino ulteriormente. " +
            "Il dialogo con gli spiriti minori non rimase nascosto a lungo al Trementor, che iniziò a concentrare le sue forze in guerre sulle piane vicine al mondo terreno, così da rendere ardui ulteriori contatti.\n\n" +
            "La battaglia continuò ancora qualche secolo, fino a che un piccolo spiritello, invocato quasi per gioco da un gruppo di giovani apprendisti sacerdoti, riuscì a rivelare agli uomini il nome di uno dei figli minori di Makmar. " +
            "I giovani raccontarono l'accaduto al gran sacerdote del tempo, un anziano di nome Phanaki, che ne fu estasiato. Il gran sacerdote Phanaki diede l'ordine a tutti i consacrati di tentare un invocazione del dio minore appena rivelato. " +
            "La preghierà durò mesi, fino a che accadde che uno di questi uomini, un sacerdote umile di una città di provincia di nome Kormar, riuscì a invocare con successo il dio minore. " +
            "Makmar non aveva ancora deciso quale sarebbe stato il primo a essere divino che sarebbe stato conosciuto dall'uomo, ma accettò di buon grado quell'avvenimento. " +
            "Il dio minore Arzik, sentendo il suo nome invocato, abbandonò la sua postazione celeste e si diresse sulla dimensione terrena, attirato da un canto che non aveva mai sentito. " +
            "Quel canto era la preghiera di un uomo, che chiedeva aiuto nel comprendere il piano divino. " +
            "Arzik giunse fino alla barriera con il mondo terreno, la dimensione di Almorah. Per lui, la barriera era ora aperta.",
        },
        {
          type: "title",
          value: "Alleanza con i primogeniti della terra",
        },
        {
          type: "text",
          value:
            "Arzik cadde nel mondo di Almorah come una saetta, catapultato in una realtà completamente diversa da qualunque cosa avesse mai sperimentato. Nell'accedere al mondo terreno ricevette una forma fisica, e sentì per la prima volta il tocco del vento, dell'erba, il suono degli animali e della natura. " +
            "Rimase sbalordito dalla bellezza che lo travolse e si sentì in pace. Spinto dalla natura di quelle nuove, inedite emozioni, Arzik prese a bighellonare per il mondo. Presto trovò le prime persone. Queste lo vestirono, gli offrirono cibo, provarono a insegnargli la loro lingua. " +
            "Era come un bambino. Non sapeva nulla di quel mondo, anche se ne aveva creato le fondamenta con la sua sola esistenza. La voce dell'uomo che lo aveva invocato rimase impressa in lui come una traccia che lo spinse a cercarne la fonte. " +
            "Dopo alcuni giorni, Arzik trovò Kormar. Il sacerdote era un uomo di mezza età, con una famiglia e una piccola cappella a lui assegnata. Era un uomo semplice, ma di cuore puro. " +
            "Incontrato Arzik, ne avvertì la natura divina, e lo accolse come un ospite d'onore. I due partirono subito. Kormar volle condurre Arzik nella città di Esfelia, capitale di Amrath. " +
            "Fu un viaggio di quasi un mese, condotto con un carretto trainato da un asino. Arzik si divertì molto, e imparò molto. La sua natura divina gli permetteva di apprendere quasi istantaneamente la sostanza di ogni realtà umana con cui si confrontava. " +
            "Una volta giunti al grande tempio di Esfelia, Arzik fu condotto da Kormar fin davanti al capo del suo ordine, il gran sacerdote Phanaki.\n\n" +
            "Temendo che fosse tutta una messa in scena, Phanaki ordinò che il presunto dio minore fosse messo alla prova. " +
            "Arzik, che non aveva mai tenuto grandi discorsi, si sentì in imbarazzo. Non sapeva cosa fare. " +
            "Kormar, che aveva imparato a conoscerlo, gli chiese di raccontare la sua storia. " +
            "Arzik, fidandosi di quell'uomo, iniziò a raccontare. La sua voce era come una sinfonia. " +
            "Non tutti credettero alla sua storia, e Phanaki era ancora molto scettico. Temeva fosse una truffa per rapinare i tesori del tempio. " +
            "Venne concesso ad Arzik di vivere tra gli uomini per del tempo, con l'occasione avrebbe dovuto dimostrare la sua reale natura divina. " +
            "In quel tempo Arzik si innamorò della cultura umana e della vita di Amrath. Imparò a parlare la loro lingua, a mangiare il loro cibo, a dormire nei loro letti. " +
            "Adorava la loro cultura, e in particolare aveva un debole per le scienze e le arti. " +
            "Su ordine di Askiaa, nel contempo, Arzik insegnò agli uomini la preghiera, e come invocare le potenze divine. Rivelò agli uomini i nomi dei sette dei minori di cui lui faceva parte e raccontò all'uomo della battaglia celeste ancora in corso.\n\n" +
            "Purtroppo il miracolo di incontrare gli altri dei minori non avvenne. Quando interrogarono Arzik al riguardo, egli rispose che in quel periodo gli altri dei erano troppo occupati ad affrontare le forze del Trementor per poter scendere sulla terra. Anche se invocati correttamente, gli altri dei minori rifiutarono di accedere al mondo terreno per non abbandonare Askiaa. " +
            "Questo silenzio degli altri dei creò in alcuni uomini il sospetto che Arzik mentisse e non sapesse nulla del divino. Temendo per la destabilizazione della sua posizione di potere, anche Phanaki alimentò queste voci. " +
            "Phanaki non era uno sciocco, e aveva intuitò da tempo che c'era qualcosa di non umano in Arzik. Alla vista della popolarità sempre crescente di Arzik, Phanaki ordinò che fosse avvelenato con una pozione che avrebbe ucciso un uomo. " +
            "Il veleno di serpente fu somministrato di nascosto ad Arzik durante un banchetto, e lui sopravvisse senza nemmeno accorgersi dell'attentato alla sua vita. " +
            "Phanaki, che aveva assistito alla scena, si rese conto che Arzik era un dio. La paura per l'imminente perdita della sua autorità religiosa divenne da quel giorno la sua ossessione.\n\n" +
            "Quasi un anno dopo, Phanaki vide l'opportunità di far fallire il dio in modo plateale. Phanaki lo chiamò davanti a sé alla presenza del re di Amrath. " +
            "Una provincia di Amrath era in guerra con altro popolo di quel tempo. Phanaki chiese ad Arzik di intervenire e salvare quella provincia senza impiegare l'esercito della capitale. Il popolo nemico era più numeroso e più potente di quello della provincia. " +
            "Phanaki era sicuro che Arzik sarebbe crollato e mandato in esilio. Arzik, che aveva imparato a conoscere il popolo di Amrath, si sentì in dovere di aiutarli. " +
            "Il dio fece radunare i migliori uomini di scienza di tutto il paese, e con loro fondò un centro di ricerca tecnica dove si studiavano le leggi fondamentali della realtà. " +
            "Arzik insegnò agli uomini come trovare le energie nascoste nella natura e come convogliarle per creare macchine che potessero muoversi, volare, e perfino sparare. " +
            "In pochi mesi, i migliori scienziati di Amrath avevano sviluppato armi e tecniche di difesa che permisero al popolo di respingere l'attacco nemico. " +
            "Con le nuove armi e le nuove tecniche, il popolo di Amrath riuscì a sconfiggere il nemico e a liberare il paese. " +
            "La vittoria fu festeggiata come un miracolo, e Arzik fu acclamato come un dio. ",
        },
        {
          type: "title",
          value: "La gloria di Amrath",
        },
        {
          type: "text",
          value:
            "Arzik era rimasto stupito di quanto potere avessero gli uomini, specialmente quando venivano guidati dalla sapienza divina. " +
            "Rimase in Amrath per altri due anni, durante i quali insegnò agli uomini come costruire macchine sempre più complesse e come sfruttare le energie nascoste della natura. " +
            "Studiando con loro apprese che alcuni aspetti della realtà materiale erano in grado di influenzare la realtà spirituale. " +
            "Più precisamente, alcune energie che in Aetherius erano inviolabili, in Almorah potevano essere manipolate. " +
            "La tecnica umana unita alla sapienza divina poteva rivelarsi il punto di svolta nella battaglia contro il Trementor. " +
            "Egli informò il re e il consiglio dei religiosi sulle sue considerazioni, e annunciò che sarebbe partito e tornato in cielo. Era convinto di poter convincere i suoi fratelli a dare un'occasione agli uomini. " +
            "Disse che sarebbe tornato presto, e invitò gli uomini a pregare per il ritorno suo e di tutti i suoi fratelli. Ordinò che ci fossero rituali di preghiera solenni ogni mese, senza mai desistere. " +
            "Questo era necessario per mantenere aperto il portale tra Aetherius e Almorah. " +
            "Phanaki, lieto di potersi togliere di torno il dio, benedì la sua partenza e incoraggiò tutti ad accogliere la decisione del dio. " +
            "In cuor suo pensava che una volta partito il dio, avrebbe potuto alterare le preghiere così da renderle inefficaci, e impedire agli dei di tornare per sempre.\n\n" +
            "Così, il dio Arzik chiese ad Askiaa di richiamarlo in cielo, e si preparò a partire. " +
            "Infatti, proprio come la barriera tra Aetherius e Almorah richiedeva il consenso degli uomini quando si doveva entrare nel loro mondo, così richiedeva il consenso di Makmar per entrare nel mondo spirituale. " +
            "Askiaa, sentendo la preghiera di Arzik intercedette per lui presso Makmar, che fu lieto di richiamare a se Arzik.\n\n" +
            "Il giovane dio divenne una torre di fuoco e salì in cielo come un fulmine. Nell'abbandonare il mondo materiale, perse il corpo e tornò nella sua forma di puro spirito. " +
            "Una volta giunto in cielo, Arzik si presentò davanti ad Askiaa e ai suoi fratelli e gli raccontò la sua esperienza. " +
            "Tutti loro furono stupiti dalla sua storia, e si resero conto che la battaglia contro il Trementor era giunta a un punto critico. " +
            "Il male del Trementor aveva causato molte piaghe nel mondo spirituale, e pian piano, sempre più creature della luce cedevano alle sue lusinghe. Se fosse continuata a lungo, ci sarebbe stato uno squilibrio di forze. " +
            "Il Trementor sapeva che alla lunga avrebbe consumato tutto il mondo spirituale. Doveva solo insistere con infinita e tremenda pazienza. " +
            "Al consiglio dei sette dei con Askiaa, Arzik disse che se quattro di loro fossero tornati su Almorah, la battaglia in cielo sarebbe rimasta equilibrata a sufficienza. " +
            "Altri dei dissero che era troppo pericoloso, e che non avrebbero dovuto rischiare. Se i dei minori avessero lasciato il regno spirituale incustodito, il Trementor avrebbe solo accelerato il suo processo di corruzione. " +
            "Il dibattito fu acceso, e Askiaa ascoltò a lungo i suoi fratelli minori. Infine si ritirò in isolamento. " +
            "Askiaa si consigliò con Makmar, che gli disse di fare come Arzik aveva detto. " +
            "Così Askiaa scelse tre dei suoi fratelli minori e li inviò indietro sulla terra con Arzik.\n\n" +
            "Ogni mese il portale per Almorah si apriva come pianificato. Phanaki non era riuscito ad impedire a tutta Amrath di pregare correttamente. " +
            "Così i fratelli tornarono. I dei minori che scesero sulla terra furono: Arzik, Hurna, Shelmalakia e Querell." +
            "Tutti loro caddero in luoghi diversi della provincia di Amrath, ciascuno in prossimità del luogo dove la preghiera di invocazione aveva meglio lavorato in suo favore. " +
            "Arzik cadde in un campo di grano, Hurna in una foresta, Shelmalakia in una città e Querell in un deserto. " +
            "Ognuno di loro fu accolto da un gruppo di uomini che li riconobbero, li vestirono e prontamente li condussero ancora a Esfelia. " +
            "Una volta riuniti, i quattro dei minori si presentarono al re e al gran sacerdote Phanaki. Il gran sacerdote, non aspettandosi il loro ritorno, ebbe un attacco di cuore nel vedere che ora gli dei da eliminare erano quattro. " +
            "Crollò a terrà e morì. Arzik, conoscendo il potere di Hurna, le chiese di proteggere la vita del povero Phanaki. Istruita da Arzik, Hurna pronunciò il nome del gran sacerdote e lui resuscitò subito. " +
            "Sotto gli occhi sconvolti di tutti, gli dei avevano compiuto un miracolo. Il re e tutta la corte ne furono estasiati. Phanaki fu aiutato a rimettersi in piedi e prese posto ai piedi del re. " +
            "Trascorsero così degli anni. A differenza di Arzik, dio minore dell'intelligenza, gli altri tre dei non erano tanto rapidi a imparare cultura, lingua e usanze quanto lo era stato lui. Ognuno dei dei minori infatti aveva un talento diverso. " +
            "Hurna, scoprendo la vita terrena, ne rimase innamorata perdutamente. Iniziò a custodirla e ad alimentarla. Negli anni della presenza di Hurna in Amrath, tutte le donne davano molti figli alla luce, anche le dichiarate sterili. " +
            "Shelmalakia aveva il dono di conoscere lo sconosciuto. Era la rivelatrice di segreti. Collaborando con Arzik e gli scienziati migliori di Amrath, aiutò a costruire macchine che potevano vedere oltre il velo della realtà. " +
            "Querell, infine, era il dio della pace. Aiutò il re a scrivere una dottrina di regole e una carta dei diritti, quindi fece fondare un ministero della giustizia che era il più avanzato, equo e invidiato del mondo. " +
            "In quegli anni Amrath divenne tanto potente da inglobare quasi ogni altra regione e popolo di Fedora.\n\n",
        },
        {
          type: "title",
          value: "La creazione del Mion'Mir",
        },
        {
          type: "text",
          value:
            "Dopo quattro anni di lavoro incessante, gli dei minori e gli uomini di Amrath avevano ultimato la costruzione di un macchinario che consumava essenza divina come fonte di energia. In cambio, questo macchinario permetteva di plasmare la realtà in tutti i suoi livelli. " +
            "Il macchinario, chiamato Mion'Mir, dava all'uomo un potere simile a quello di Makmar. " +
            "Il Mion'Mir era un congegno complesso, fu posto in una cupola larga centinaia di metri di diametro, sopra una torre alta quanto una montagna. " +
            "Vari esperimenti fatti con il Mion'Mir dimostrarono che esso poteva creare e distruggere la materia, plasmare la realtà, e persino influenzare il destino dell'universo. " +
            "Il Mion'Mir era un'arma potentissima, e gli uomini di Amrath erano i suoi padroni. " +
            "Arzik si offrì molte volte come fonte di energia per gli esperimenti minori. Con l'uso del Mion'Mir il re di Amrath potè creare un esercito di soldati invincibili, costruire città in un giorno, e persino creare nuove forme di vita. " +
            "Quando un dio si consumava per alimentare il Mion'Mir, la sua essenza si disperdeva nell'aria e si univa a quella degli uomini. " +
            "Dopo un anno di esperimenti, Arzik era diventato un dio debole e stanco. " +
            "Shelmalakia, patendo per le condizioni del fratello, si mise alla ricerca di una soluzione. Esplorando i segreti della realtà in se, scoprì che l'essenza divina si poteva restituire solo in un modo. " +
            "Esisteva una forma di preghiera devozionale che permetteva di restituire l'essenza divina a un dio. " +
            "Venne perciò fondato il culto di Arzik, e gli uomini di Amrath iniziarono a pregare per la sua salute. Fu chiamato il culto dell'arco, e presto venne esteso alla venerazione di tutti i sette dei minori e di Askiaa stesso. " +
            "La sua completa ripresa impiegò solo pochi giorni grazie al potere di quelle preghiere. " +
            "Dopo ancora due anni, ormai gli uomini di Amrath e i quattro dei avevano capito bene come usare il congegno. Il re era morto e suo figlio gli era succeduto. Come per il padre, anche per lui fu un onore collaborare con gli dei per distruggere il Trementor. " +
            "Ci fu un primo tentativo. I quattro dei assieme si offrirono come fonte di energia in simultanea. Il Mion'Mir fu acceso, e la sua potenza fu tale che scosse il regno spirituale. " +
            "La preghiera contro il Trementor, pensata per sigillarlo fuori dalla realtà, nella regione dell'oblio, il Guaniolt. Arzik aveva insegnato al gran sacerdote esattamente come pregare e stava per funzionare. O almeno, così sembrava." +
            "Dopo i primi minuti di funzionamento, il Mion'Mir si spense. Il Trementor aveva reagito. " +
            "Il Trementor era troppo potente e il Mion'Mir non era in grado di sigillarlo. Alimentato da solo quattro dei minori, non aveva il potere di manipolare il Trementor, che rispetto a loro era immensamente superiore. " +
            "Arzik e gli altri dei interruppero il rituale. Divenne loro chiaro che c'era bisogno di più energia. " +
            "Solo Askiaa poteva dare loro quell'energia. Il re e i sacerdoti furono entusiasti di quell'informazione. Da tempo sognavano di vedere il più grande dio della luce. " +
            "Arzik, che non era uno sciocco, sapeva che questo non sarebbe stato privo di rischi.\n\n" +
            "Senza Askiaa nel mondo spirituale, il Trementor avrebbe corrotto tutto in pochi secondi. " +
            "C'era solo una possibilità. Se Askiaa avesse dovuto lasciare il mondo spirituale, anche il Trementor avrebbe dovuto seguirlo.",
        },
        {
          type: "title",
          value: "La caduta del Trementor",
        },
        {
          type: "text",
          value:
            "Dopo aver valutato a lungo i rischi, Askiaa decise di scendere sulla terra e seguire il piano di Arzik. " +
            "Venne chiesto al re di Amrath di organizzare un grande rituale di invocazione per Askiaa nell'anno 126. Al tempo stesso, Arzik diede al più santo dei sacerdoti, l'anziano eremita Zalmao, il compito più ingrato. Gli consegnò una pergamena con su scritto l'immondo rituale di evocazione del Trementor. " +
            "Diede a Zalmao il compito di recarsi in un luogo remoto con otto discepoli tra i più preparati. Il Trementor doveva essere evocato lontano da loro e dal Mion'Mir. Arzik contava sul fatto che il Trementor sarebbe stato per qualche istante confuso dalla realtà materiale, e quel tempo doveva bastare loro per completare il sigillo su di lui. " +
            "Il re e i sacerdoti si prepararono per mesi. Quando il tempo giunse, Zalmao partì portando con se la pergamena nera. " +
            "Nel giorno stabilito, il re e i sacerdoti iniziarono il rituale di invocazione di Askiaa. " +
            "Il re, che era un uomo di grande fede, pregò con tutto il cuore. " +
            "Askiaa, che era un dio di grande potere, sentì la preghiera e si diresse verso il mondo terreno, ma non si mosse prima di aver visto il Trementor fare altrettanto. " +
            "Il Trementor odiava gli uomini, e l'accesso al loro mondo sembrò un'irripetibile fortuna. Egli però, che era un dio di grande astuzia, fece una cosa inattesa. " +
            "Prese con se i suoi tre fedeli dei minori e li divorò, ingoiandoli interi, quindi si diresse nel mondo degli uomini. " +
            "Tutti i popoli sentirono la terra tremare mentre un'onda di nuvole nere e fiamme si alzava dal sud. Il mostro ottenne un corpo e li dove cadde si formò una valle che subito fu maledetta, e tutta la vita nel raggio di chilometri fu ridotta in cenere. " +
            "Il Trementor, finalmente libero di agire, urlò contro Askiaa e Makmar, poi si piegò su se stesso e vomitò i suoi tre fratelli minori. " +
            "Questa cosa Arzik non l'aveva prevista. Il Trementor aveva ingannato tutti, aveva trovato un modo di condurre nel mondo tramite se i tre demoni minori: Urhari, il distruttore, Mamellon il corruttore e Ential il dissacratore. " +
            "Così i figli minori dell'oscurità furono liberi di scagliarsi contro tutto ciò che trovarono: città, villaggi, foreste, fiumi. " +
            "A quella vista, tutta l'umanità cadde preda della disperazione, ma non i sacerdoti che erano con il re di Amrath. Incitati dai quattro dei che erano con loro, continuarono a pregare. " +
            "A quel punto, Askiaa potè entrare nel mondo e lasciare incustodito il regno spirituale. Da un'arco nel cielo scese l'angelo della luce, come una cometa dal caldo bagliore. " +
            "Calò con delicatezza sulla terra e si diresse davanti ai quattro dei minori, che si inchinarono. Con loro, tutto il popolo di Esfelia si inchinò. Il re, faccia a terra, implorò Arzik di sbrigarsi, in quanto la presenza nel Trementor sul mondo terreno era da sola sufficiente a stringere i cuori degli uomini nel terrore. " +
            "Arzik accompagnò Askiaa al Mion'Mir, dove un trono lo attendeva. Era stato appositamente costruito per assorbire il dio e la sua essenza. " +
            "L'angelo di luce sedette e, a seguito del rituale di attivazione, iniziò ad essere assorbito. " +
            "Il gran sacerdote Maskar, al tempo in carica, eseguì il rituale contro il Trementor. " +
            "Stavolta il Mion'Mir, accedendo al potere di Askiaa, riuscì a sortire il suo effetto. " +
            "La forza spirituale del Trementor fece resistenza, e il Mion'Mir dovette assorbire in modo definitivo Askiaa, consumandone interamente il corpo. " +
            "Sotto gli occhi di tutti, Askiaa fu distrutto dal Mion'Mir e il Trementor venne bandito per sempre nel Guaniolt. " +
            "I cieli e la terra sollevarono un lungo lamento per la perdita di Askiaa. Una luce nell'anima di tutte le creature era appena stata spenta. " +
            "Il Mion'Mir aveva lavorato con tanta potenza, che quasi fu distrutto dal suo stesso potere. " +
            "I demoni del Trementor, sconvolti da quell'avvenimento, vedendo il loro padrone distrutto, si ritirarono fuggendo. Con loro, tutte le ombre e le piaghe sparirono. " +
            "I tre dei minori rimasti nel mondo spirituale finirono di distruggere le forze residue degli esseri fedeli al Trementor. La battaglia era vinta.",
        },
        {
          type: "title",
          value: "La vendetta di Urhari e la caduta di Amrath.",
        },
        {
          type: "text",
          value:
            "Passarono molti anni dalle vicende della sconfitta del Trementor. I sette dei minori, eredi del compito di Askiaa, scendevano a turno sulla terra e si impegnarono a collaborare con il re di Amrath per creare un popolo luminoso e glorioso, che rendesse onore al piano di Makmar. " +
            "Uno di loro, Lanthamos, non tornò mai in cielo. Era preoccupato dal pericolo delle forze oscure supersiti rimaste sulla terra. Le cercava e le sterminava, ma non trovava mai i suoi veri bersagli: i tre demoni del Trementor. Li cercò ovunque, per secoli, ma di loro non vi era traccia. " +
            "Arzik pensava che in qualche modo fossero tornati nel mondo degli spiriti, dove avrebbero atteso nascosti in silenzio la loro definitiva esclusione dalla creazione. " +
            "Seguirono secoli di luce, in cui la forza dell'uomo e la statura della sua sapienza raggiunse la maturità. I sette credevano che presto Makmar avrebbe potuto completare la sua opera di creazione e unirsi con l'uomo. " +
            "Il lavoro era quasi finito. Tutti gli uomini erano uniti sotto un unica bandiera, e tutti erano formati al divino insegnamento dei sette. Nulla sembrava poter più danneggiare la luce. ",
        },
        {
          type: "text",
          value:
            "Nell'anno 2126 Urhari, il distruttore, decise di rompere il suo silenzio. Era passato molto tempo e i sette dei erano distratti dai floridi risultati del popolo di Amrath, " +
            "nessuno di loro si aspettava più alcuna mossa dalle forze delle tenebre, che anche i sette dei iniziarono a considerare del tutto sconfitte. Urhari spese del tempo per aggirarsi nel mondo " +
            "sotto le sembianze di un viandante solitario. Apprese le culture del mondo e conobbe molti popoli. Infine, nell'anno 2244, decise di avvicinare il re di Amrath fingendosi un ricco principe di un " +
            "regno lontano, giunto per onorare il grande re di Amrath, al tempo Hikhaard Saulum. " +
            "Urhari sapeva come lusingare il cuore degli uomini, e il re di Amrath, che era un uomo suscettibile alla venerazione, lo accolse rapidamente nella cerchia dei suoi cortigiani più fidati. " +
            "Il dio oscuro riuscì a divenire presto consigliere del re, in quanto possedeva un'intelligenza che suscitava ammirazione. ",
        },
        {
          type: "text",
          value:
            "Urhari manipolò il re di Amrath per anni, lavorando sulle sue paure e i suoi dubbi e accecandolo con le sue ambizioni. " +
            "Il re presto finì in potere del dio oscuro che lo convinze, dopo anni di lavoro, a utilizzare nel 2266 il Mion'Mir per accedere al potere degli dei. " +
            "Urhari aveva convinto il re che gli dei volevano spodestarlo e che lui avrebbe dovuto agire di anticipo, sfruttando quel potente artefatto, per ascendere al livello divino. " +
            "In segreto, Urhari voleva spingere il re a usare il Mion'Mir per annullare l'ultimo sigillo creato, quello che teneva il Trementor imprigionato. " +
            "Quello che Urhari non sapeva era che il Mion'Mir era un macchinario molto complesso, e il suo potere era limitato e soggetto a regole ben precise. " +
            "Quando una persona accedeva al Mion'Mir otteneva uno stato di chiarezza mentale particolare che eliminava ogni alterazione esterna. " +
            "Il re, manipolato da Urhari, entrò nel Mion'Mir e in quel momento smise di ascoltare i consigli del dio oscuro grazie all'influsso del Mion'Mir. " +
            "La sua mente ormai, era fuori controllo. Il re chiese l'immortaità, assumendo su di se il destino di vita futura del suo popolo. Fu per questo che il popolo di Amrath venne maledetto e devastato dal Mion'Mir, come se " +
            "avesse applicato un contrappeso al desiderio espresso dal re. ",
        },
        {
          type: "text",
          value:
            "La distruzione che seguì sconvolse la terra. Ovunque il popolo di Amrath aveva esteso i suoi domini, li la terra tremava, il mare indava e la tempesta colpiva con violenza. " +
            "Le donne del popolo divennero sterili e i superstiti dei cataclismi furono condannati a invecchiare senza lasciare seguito. " +
            "Urhari, preoccupato di essere individuato, fuggì e si nascose. Quando i sette dei seppero dell'accaduto, ormai era troppo tardi. " +
            "Infuriati per l'uso sconsiderato che il re aveva fatto del Mion'Mir, si adoperarono per nascondere il reperto e divisero in quatro la sua chiave di accenzione. " +
            "Il Mion'Mir e le quattro chiavi vennero nascoste, e i sette dei si ritirarono nel mondo spirituale a valutare come procedere. ",
        },
        {
          type: "title",
          value: "La divina misericordia e la liberazione dalla maledizione",
        },
        {
          type: "text",
          value:
            "Nell'anno 2275, il dio Arzik, che era il più vicino al popolo di Amrath, decise di scendere sulla terra e vedere con i suoi occhi la devastazione. " +
            "Commosso dalla distruzione e dai costanti lamenti del popolo, si ingegnò per aggirare la maledizione del Mion'Mir. Il suo desiderio era aiutare il popolo a sopravvivere, " +
            "nonostante tutto. Così, in accordo con altri tre dei, Hurna, Tolkanigan e Khilmitaur, si adoperarono per promulgare l'offerta del ripristino del popolo di Amrath. " +
            "Attraverso i sacerdoti dell'arco, fecero sapere al popolo che se avessero pregato per la liberazione dalla maledizione, essi avrebbero potuto ottenere la grazia divina. " +
            "In cambio della rinuncia alla loro identità, alla loro cultura, a tutto ciò che li caratterizzava, avrebbero potuto avere salva la vita. " +
            "Molti pregarono e accolsero la proposta, e così il popolo di Amrath fu salvato dalla distruzione. " +
            "Il popolo perse la memoria di se, e si rialzò dalle macerie della sua stessa cività senza riconoscerla. " +
            "I sette dei videro che l'idea di Arzik aveva funzionato. Le donne erano tornate fertili e il Mion'Mir aveva smesso di condurli alla morte. " +
            "Così, dall'anno 2276 i sette dei si separarono per il mondo per aiutare i primogeniti a ripartire con la loro storia, e con l'aiuto divino divennero i secondo geniti, eredi inconsapevoli della prima era. ",
        },
        {
          type: "text",
          value:
            "Passarono molti secoli, e i secondogeniti inizarono a fortificarsi. Nel 2553 fu fondato con l'aiuto dei sette il regno di Stjulma. " +
            "Quel regno sarebbe stato l'erede spirituale dell'impero di Amrath. I sette dei infatti puntavano a usare quel regno per " +
            "ricondurre tutti i popoli divisi sotto un'unica bandiera e condurre ogni persona alla fede nei sette. Purtroppo quel popolo " +
            "non seppe condurre una vita degna della grazia ricevuta, e iniziò ad annettere i popoli con la guerra invece che con la fede nei sette. " +
            "Negli anni dal 2578 al 2585 il regno di Stjulma si espanse fino a coprire quasi tutto il continente. " +
            "I sette non erano contenti di come il regno di Stjulma stava procedendo, tuttavia non potevano fermarlo subito in quanto era " +
            "la migliore occasione che avevano di riunire i popoli sotto un'unica bandiera. La risposta fu negativa e i sette, per questo, si allontanarono da loro. " +
            "Infine, nell'anno 2586 il regno di Stjulma venne riconosciuto come impero del continente, e l'anno 2586 è identificato come l'anno in cui si rompe " +
            "definitivamente con l'era passata e si entra nel tempo pieno dei secondo geniti. ",
        },
      ],
    },
    {
      title: "Seconda era",
      shortDescription: "La seconda era di questo mondo",
      content: [
        {
          type: "title",
          value: "Ripudio di Stjulma, elezione dei Casadri",
        },
        {
          type: "text",
          value:
            "I sette figli di Askjiaa, delusi dal comportamento eccessivamente bellicoso del " +
            "popolo di stjulma che si rifiuta di passare a vie più pacifiche, decidono di spostare in silenzio la loro grazia altrove. " +
            "Nell'anno 47 della seconda era, viene identificato un piccolo popolo che sembrava essere per sua costituzione più " +
            "conforme alle linee guida dei sette. Questo popolo, chiamato Casadri, viene eletto come nuovo popolo eletto dei sette. " +
            "Il popolo dei Casadri però non aveva grande confidenza con i sette dei, e loro, per dimostrare la serietà " +
            "del loro impegno ad aiutarli, costruirono una grande fortezza in cui loro avrebbero convissuto con la famiglia reale dei Casadri " +
            "per aiutarli a prendere coscenza del destino a cui erano stati avviati. ",
        },
        {
          type: "text",
          value:
            "Nel 49 fu completata la fortezza di Ulum, al centro del regno dei Casadri. Una struttura alta duecento metri e larga come una piccola cittadella. " +
            "Era un gioiello di roccia chiara e cristallina, che brillava al sole. I suoi vissilli mossi dal vento erano visibili ai viandanti da molte miglia di distanza. " +
            "Alla luce di quel dono, i Casadri accettarono l'alleanza con i sette e accolsero la loro chiamata. " +
            "Grazie a questa alleanza con i divini, i Casadri guadagnarono subito il rispetto di molti popoli vicini che fecero a gara per unirsi a loro. " +
            "Questi popoli infatti temevano l'azione del regno di Stjulma, che aveva iniziato a muoversi anche verso di loro. ",
        },
        {
          type: "text",
          value:
            "Nel 69 della seconda era il re di Stjulma scoprì che i sette si erano spostati a benedire i Casadri, così " +
            "decise di attaccare i popoli a loro limitrofi per distruggere tutti gli altari dei sette che riusciva a trovare. " +
            "Quando la guerra pareva incombente e i Casadri erano in pericolo, avvenne qualcosa di inatteso. " +
            "Il re di Sjulma morì di vecchiaia e suo figlio, salito al trono, si dichiarò fedele ai sette. Il giovane re infatti non condivideva la " +
            "ribellione del padre ai sette dei e quando ebbe l'occasione, dimostrò la sua lealtà ai sette ritirandosi dalla guerra imminente. " +
            "Il regno di Stjulma ottenne grazia dai sette per questa azione, ed entrò in una lega commerciale pacifica con i popoli del continente, incluso il regno dei Casadri. ",
        },
        {
          type: "text",
          value:
            "Nel 131 della seconda era i Fakatan, annessi dal regno di Skjulma negli anni precedenti, decide che non si rispecchiano più nelle politiche " +
            "del re e avviano una guerra di indipendenza coloniale. I Fakatan erano una frazione del regno di Stjulma che viveva nel nord'ovest di Fedora ed erano gli unici a godere di una ricca " +
            "rotta commerciale verso i popoli che vivevano nelle isole maggiori del continente di Vaniaa, di cui erano lontani parenti. " +
            "Le guerre durarono fino al 135, anno in cui finalmente ottennero l'indipendenza. I Fakatan emigrarono perciò in massa verso Vaniaa, dove i popoli " +
            "indigeni li accolsero come alleati. ",
        },
        {
          type: "title",
          value: "Il ritorno del dio oscuro, la conquista di Vaniaa",
        },
        {
          type: "text",
          value:
            "Nell'anno 167 della seconda era, Urhari uscì dal suo nascondiglio per valutare la situazione del continente. " +
            "Vide che i sette dei avevano eletto un nuovo popolo e che il regno di Stjulma era in pace con i suoi vicini. " +
            "Temendo di essere individuato, Urhari evitò di intromettersi nella storia dei popoli di Fedora. " +
            "Considerò infatti troppo rischioso immischiarsi nelle vicende di quel continente che era così attenzionato dai sette dei nel loro " +
            "tentativo di ripristinare lo splendore della prima civiltà." +
            "Così Urhari decide di spostarsi verso Vaniaa, un continente che i sette, almeno per quel momento, stavano trascurando. " +
            "Giunse nel 171 a Shora ed esplorò a lungo quelle terre in cerca di un popolo che potesse essere manipolato. ",
        },
        {
          type: "text",
          value:
            "Nel 193 Urhari scoprì l'esistenza di un popolo avverso ai sette e che era per natura predisposto ad atti malvavi. " +
            "Era il popolo di selvaggi retto dal mago oscuro Vorolum e una setta di suoi seguaci suoi fidati. " +
            "Urhari si avvicinò a Vorolum e gli offrì il potere divino in cambio della sua lealtà. Vorolum accettò e divenne il primo dei seguaci di Urhari. " +
            "Scrisse sotto l'azione del dio oscuro il Nicromicron, un libro che conteneva i segreti della magia oscura e che avrebbe permesso a chiunque lo leggesse di creare patti con i dei delle ombre e i loro demoni. " +
            "Urhari, compiaciuto di quel popolo malvagio, decise di stabilirsi in pianta fissa tra loro e fondo la sua fortezza oscura nelle loro terre. " +
            "Nel 196 sorse la fortezza nera di Argramar, centro operativo di tutte le forze del male di Vaniaa. ",
        },
        {
          type: "text",
          value:
            "Nel 199 Arzik, durante le sue perlustrazioni occasionali, dopo aver seguito la pista di una voce che aveva sentito, di un grande male " +
            "all'opera nelle terre di Vaniaa, giunge infine a scoprire la fortezza di Argramar. " +
            "Arzik ignora che sia opera di Urhari e la considera solo una fortezza di un qualche oscuro stregone. " +
            "Decide di tornare in patria e informa i suoi fratelli delle sue scoperte. Loro però, non ritenendola una questione critica, decisero di sorvolare sulla cosa.",
        },
        {
          type: "title",
          value: "L'impero malvagio di Quasdora",
        },
        {
          type: "text",
          value:
            "Nel 210 Urhari considera consolidato il suo potere in Vaniaa e decide di spostarsi con alcuni " +
            "uomini scelti verso il continente di Fedora. Li troverà altri alleati malvagi, come nel 212, quando " +
            "strinse alleanze con i demutani del gruppo nomade Tikyorma, i mezzo spettri. " +
            "Urhari cercò a lungo una terra che fosse disabitata e lontana dalle attenzioni dei sette dei della luce. " +
            "L'ultima cosa di cui aveva bisogno era uno scontro diretto con loro prima di essere del tutto pronto. " +
            "Nel 216 Urhari fonda la sua terra a sud di Fedora, (futura Olongea), e chiama il suo regno Quasdora. " +
            "Masmithra, la fortezza del male, si erge al centro di questa landa in cui scende una notte perenne. " +
            "Li si ritira in quel luogo per accentrare ulteriormente le sue forze. La presenza di Masmithra viene " +
            "identificata da Arzik due anni dopo la sua fondazione, ma anche in questo caso, viene scambiata per l'opera di " +
            "qualche negromante o mago oscuro. I sette non sospettano ancora il coinvolgimento di Urhari. ",
        },
        {
          type: "text",
          value:
            "Da Masmitra pian piano si diffonde il potere di Urhari, che soggetta le terre vicine e prende il " +
            "controllo delle isole dei regni di Photgaria, Smishari, Adkildur (zone del futuro dulenvir). Queste si " +
            "sottomettono a lui dopo aver perduto alcune tragiche guerre contro gli eserciti dannati e i predoni guidati da Vorolum. " +
            "Quando i sette si resero conto del male che era all'opera nel sud di Fedora, ormai era troppo tardi. " +
            "Le forze oscure si erano arroccate in quelle terre e i sette non avrebbero potuto attaccarle senza mettere in pericolo i popoli del centro nord, " +
            "che erano il loro orgoglio e la loro speranza. I sette crearono un confine sicuro per i popoli dei Casadri e i loro alleati e si chiusero in difesa delle loro terre, " +
            "in attesa di renderli abbastanza forti e maturi da poter invadere e liberare i popoli del sud. ",
        },
        {
          type: "text",
          value:
            "Nel 1241 infine, Arzik e i suoi fratelli decisero di attaccare il regno di Quasdora. Si sentivano pronti ad affrontare le sue " +
            "armate nere, e i popoli dei Casadri avevano radunato un esercito di oltre diecimila valorosi uomini. " +
            "Marciarono su Quasdora e la guerra durò per tre mesi e condusse gli eserciti fino alla fortezza di Masmithra. " +
            "Sulla valle di Masmithra però, subirono una terribile sconfitta. Urhari si mescolò al suo esercito e scese in battaglia sguinsagliando i molti alleati " +
            "e i poteri oscuri che aveva raccolto in quegli anni, compreso il supporto del re Osmadneio, corrotto da vili patti stretti con il dio oscuro nel segreto. " +
            "L'esercito dei Casadri si schiantò contro la sua forza e il re venne uscciso davanti ai suoi uomini migliori " +
            "da Urhari in persona, che infine si rivelò e si scagliò contro di loro. " +
            "La vista del dio oscuro fece piomnare nel terrore l'esercito dei Casadri che andò in rotta. " +
            "La sconfitta costò la vita a oltre ottomila uomini e la guerra fu persa. " +
            "I dei della luce avevano pagato un prezzo alto per la loro arroganza e furono costretti a rivalutare la loro posizione. " +
            "Quello fu l'inizio dei mille anni di ombra del sud di Fedora.",
        },
        {
          type: "title",
          value: "I campioni della luce",
        },
        {
          type: "text",
          value:
            "La sconfitta subita aveva lasciato i popoli del centro-nord in balia di dubbi e timori. " +
            "Molti parlavano di una sconfitta imminente e alcuni addirittura suggerivano di unirsi a Urhari prima che fosse troppo tardi. " +
            "I re del nord, che onoravano i sette, gli rimasero fedeli, e questo diede ai popoli del centro-nord la forza di respingere le forze di Urhari " +
            "quando queste provavano a spingere sul confine. " +
            "Nessuno poteva distruggere l'altro. Si creò un equilibrio di forze che durò per secoli. ",
        },
        {
          type: "text",
          value:
            "A partire dal terzo millennio ormai il mondo si era abituato ad essere spaccato in due. " +
            "Il sud era il regno di Urhari, il centro-nord era il regno dei sette. " +
            "La situazione però non era sostenibile e la presenza di Urhari vanificava ogni tentativo dei sette di purificare il mondo. " +
            "Purtroppo la loro sapienza non bastava a individuare un piano realizzabile per neutralizzare il dio oscuro. " +
            "Chiuso nelle sue terre, protetto dai suoi servi, Urhari era intoccabile. " +
            "La divina Shelmalakia, stanca di pianificare nuove strategie per sconfiggere Urhari, andò nel luogo dove era nascosto il Mion'Mir e si mise " +
            "a pregare lo spirito di Askjiaa, sigillato nel reperto, perchè le donasse un'intuizione su come procedere. ",
        },
        {
          type: "text",
          value:
            "L'angelo della luce, seppur consumato nel reperto, riuscì a comunicare con Shelmalakia e le suggerì di eleggere un campione tra gli uomini. " +
            "Solo unendo il potere divino allo spirito degli uomini si sarebbe potuto sconfiggere Urhari. Egli si era armato per difendersi contro i poteri dei sette, " +
            "nella sua arroganza non si era di certo preparato a difendersi contro un comune mortale. " +
            "Shelmalakia, dopo aver ricevuto la visione, si recò dai suoi fratelli e li informò della cosa. " +
            "Dopo un lungo dibattito, i sette decisero di seguire il consiglio di Askjiaa e di eleggere un campione tra gli uomini. " +
            "Identificarono nella sorella Hurna l'unica dei sette che avesse il potere di influenzare la nascita di un simile campione. " +
            "Hurna iniziò a cercare un luogo lontano dalle guerre e dai mali di Urhari e scelse il piccolo villaggio di Ubinabi. " +
            "Li, lei agì con la sua grazia per rendere fertile una giovane sacerdotessa consacrata al culto dei sette. Le apparve in sogno e le ordinò " +
            "di unirsi a un uomo del villaggio e di generare un figlio. Hurna avrebbe influenzato il concepimento in modo da alterare la forza vitale del bambino " +
            "e fornirgli una natura semi-divina. ",
        },
        {
          type: "text",
          value:
            "Nell'anno 2296 della seconda era, perciò, la donna diede alla luce un figlio, che fu chiamato Khalindar. " +
            "Quello che Hurna non aveva pianificato era la nascita del fratello gemello di Khalindar, che fu chiamato Opsei. " +
            "Nell'anno 2313 i due gemelli vengono informati dai genitori della loro origine divina e del loro destino. " +
            "Così, essi partono in cerca della dea Hurna e nel 2317 trovarono la sua valle sacra. " +
            "Li incontrarono la dea, che li attendeva fin dalla loro nascita, impaziente di scoprire se i giovani avessero ereditato da lei i poteri sperati." +
            "La dea metterà alla prova i due gemelli, chiedendo loro di compiere una serie di prove per dimostrare la loro forza e la loro lealtà. " +
            "Infine, nel 2348 li accetterà come suoi allievi.",
        },
        {
          type: "title",
          value: "La formazione degli eroi",
        },
        {
          type: "text",
          value:
            "Hurna iniziò a formare i due gemelli come suoi campioni e li iniziò ai segreti dell'antra. " +
            "Loro diventeranno i primi due praticanti del Dharma Talgalla, arte del potere segreto fornita a loro come dono divino. " +
            "Essi iniziarono a lavorare per sbloccare il Noahrama, ma erano lenti e inesperti. " +
            "Temendo di arrivare in ritardo al momento della loro prova, Arzik decise di intervenire e creò cinque libri che contenevano una sintesi " +
            "dei segreti dello spirito del mondo. Infuse in essi il suo sapere e li donò a Khalindar e Opsei perchè potessero usarli come mezzo " +
            "per una più rapida crescita spirituale. Nel 2356, grazie ai libri sbloccarono il Noahrama di secondo livello e nel 2360 quello ti terzo. " +
            "Arzik si compiacque del risultato ottenuto e seguì da vicino la loro formazione. Nel 2365 i due campioni diventano esperti nell'uso del Dharma Talgalla.",
        },
        {
          type: "title",
          value: "La guerra con Urhari",
        },
        {
          type: "text",
          value:
            "Pochi anni dopo, nel 2367, Urhari seppe che i sette stavano preparando dei campioni per affrontarlo. " +
            "Volendo colpirli prima che fossero pronti, Urhari mosse i suoi eserciti oltre i confini e sfruttò una rete di traditori coltivata " +
            "negli anni per aprirsi un varco nelle difese dei popoli del nord. " +
            "Nel 2368 il suo potere è tale che quasi tutti i regni degli uomini perdono fede nei sette e si dichiarano sottomessi a lui, riconoscendolo come signore. " +
            "Solo pochi popoli rimangono fedeli ai sette e si rifugiano nelle terre dei Casadri. " +
            "Li avvenne una cruenta battaglia che portò alla distruzione della fortezza. I sette infatti erano troppo divisi, Arzik e Hurna erano occupati a nascondere Khalindar e Opsei, e gli altri cinque dei da soli non poterono difendere i loro alleati." +
            "Non riuscendo a trovare i campioni della luce nella capitale dei Casadri, Urhari decide di abbandonare Fedora e tornare in Vaniaa. " +
            "Non sapeva cosa aspettarsi dai campioni della luce, ma non voleva rischiare e per essere sicuro di essere invincibile avrebbe avuto bisogno di usare il Mion'Mir. " +
            "Così nel 2370 tornò in Vaniaa, terra in cui sospettava che il Mion'Mir fosse nascosto, e li rimase per due anni in cerca del reperto. ",
        },
        {
          type: "text",
          value:
            "Nonostante la caduta dei Casadri, alcuni popoli di Fedora non si arresero. I regni dell'ovest si unirono in una lega " +
            "contro il signore oscuro. La lega era guidata dai capi del popolo di Severinim, del popolo dei Burukhad e del popolo dei Visir. " +
            "Sapevano che Urhari aveva abbandonato Fedora e si dissero che era il momento giusto per contrattaccare. La guerra durò fino al 2374, anno in cui " +
            "Vorolum, stanco di sentire notizie dei popoli che insorgevano, inviò una bestia che aveva allevato a cui diede il compito di scatenare una distruzione che ridusse in cenere Jumalia, la capitale dei Burukhad. " +
            "Urhari però era lontano, e gli uomini avevano ripreso coraggio. La bestia di Urhari venne uccisa da Khalindar e Opsei, che nel frattempo avevano " +
            "padroneggiato i segreti del Dharma Dorchadas. " +
            "Alcuni tra i migliori guerrieri della lega dell'ovest avevano raggiunto il braccio destro di Urhari, il mago nero Vorolum e lo uccisero. Da lui avevano ottenuto informazioni su come sconfiggere le terre del sud e si riunirono velocemente per " +
            "marciare su Quasdora, nel 2377 la lega dell'ovest riuscì a sconfiggere le forze di Urhari e a distruggere la fortezza di Masmithra dopo un assedio durato dieci anni. ",
        },
        {
          type: "text",
          value:
            "Davanti a quella terribile sconfitta, i servi di Urhari capirono che non potevano vincere finchè il loro signore rimaneva distratto a Vaniaa. " +
            "Urhari, ignaro di tutto, intanto creò in Vaniaa la sua emanazione più mostruosa, chiamata la macchina, un gigantesco volatile simile a un'acquila, " +
            "grande quanto un palazzo sul cui capo aveva montato un'artefatto che lo rendeva capace di vedere attraverso la materia " +
            "solida. A questo servo diede il compito di sorvolare Vaniaa e cercare per lui il Mion'Mir mentre eliminava eventuali intrusi. " +
            "Intanto in Fedora, i popoli della lega dell'ovest festeggiavano la caduta della fortezza oscura. Nell'anno 2389, sire Rilsir, re dei burukhad, esige la fortezza di Urhari in premio. " +
            "Questa gli fu concessa come risarcimento per la perdita della sua capitale. ",
        },
        {
          type: "text",
          value:
            "Nel 2391 i servi di Urhari trovarono il loro padrone tra le lande di Vaniaa e lo informarono delle pesanti sconfitte subite. " +
            "L'anno successivo, furioso, Urhari tornò in fedora e sguinsagliò un esercito di soldati ombra con cui avvolse il mondo in un'altra tenebra. " +
            "Ormai però, il tempo era maturo. Nel 2395 Khalindar e Opsei, che avevano appreso anche i segreti del Dharma Endelior, erano ormai pronti ad affrontare il dio. " +
            "Nel 2397 si unirono agli eserciti degli uomini liberi e riuscirono a spazzare via i nemici. Presto vennero riconosciuti come campioni divini e i popoli liberi li elessero loro generali di tutte le armate. " +
            "I due gemelli condussero un'ultima alleanza contro l'esercito di Urhari, raccolto sulla costa ovest di Fedora. Li avvenne la battaglia finale e in quel contesto, finalmente, Khalindar e " +
            "Opsei poterono affrontare Urhari a viso aperto. Urhari, impreparato nei confronti dell'enorme potere dei due eroi, venne infine sconfitto e Khalindar, com'era nel suo destino " +
            "sigillò Urhari per sempre dentro il suo stesso corpo. Finì così la minaccia di Urhari e i popoli riconobbero i due gemelli come i loro salvatori. ",
        },
        {
          type: "title",
          value: "Dopo la caduta di Urhari",
        },
        {
          type: "text",
          value:
            "Dopo la sconfitta di Urhari iniziò un periodo di guarigione di tutti i regni. Le milizie si scliolsero in gruppi di perlustratori che vagavano per le terre " +
            "in cerca dei servi di Urhari sopravvissuti e li trucidavano a vista. Il popolo dei Burukhad, che aveva perso la sua capitale, si spostò a sud e fondò la città nelle terre liberate. " +
            "Per quanto riguarda Masmithra, la fortezza oscura consegnata al re dei Burukhad, questa venne considerata maligna e il re non la volle come sua residenza. " +
            "Considerò perfino di distruggerla, ma era costruita in Amambar e non poteva essere danneggiata. " +
            "Così, tememendo che i suoi mali dormienti potessero risvegliarsi, decise di sigillarla e di non permettere a nessuno di avvicinarsi. " +
            "Attorno ad essa costruì la muraglia di Ultardis, un prodigio dei Burukhad. Una muraglia alta dieci metri e carica di automi disposti a guardia della fortezza. " +
            "Infine, nel 2421, la fortezza venne ceduta a un piccolo popolo che aveva perduto tutte le sue terre contro Urhari. Il popolo degli Shon, " +
            "si insediò nella fortezza con il consenso del re dei Burukhad e la trasformò nella base dei una prestigiosa congrega di alchimisti e incantatori. " +
            "Masmitra diventa presto un'accademia di questi individui e nasce la gilda degli incantatori.",
        },
        {
          type: "text",
          value:
            "I popoli dell'ovest e del nord intanto iniziarono a ricostruire le città e i villaggi e il culto dei sette " +
            "tornò in auge. La presenza di Khalindar e Opsei era vista da tutti gli uomini come il segno della garanzia della vittoria della luce sulle tenebre. " +
            "I due gemelli, però, non si accontentarono di questo. Decisero di fondare nel 2445 un ordine di cavalieri che avrebbe protetto i popoli di Fedora da ogni minaccia. " +
            "I cavalieri della lama d'argento ricevettero il mandato di incarnare la forza e la volontà dei sette dei. Fatto ciò i due eroi si ritirarono a vita privata per " +
            "perfezionare il loro addestramento. Finalmente la luce sembrava aver vinto le tenebre. ",
        },
        {
          type: "title",
          value: "La vendetta di Mamellon, il fratello di Urhari",
        },
        {
          type: "text",
          value:
            "Il mondo degli uomini conocque un periodo di pace e prosperità. Scienza, cultura, vita " +
            "e arte si diffusero a macchia d'olio. I pochi popoli belligeranti e pericolosi erano in minoranza e non avevano " +
            "alcun reale potere. Questo durò per circa cinquecento anni dopo la caduta di Urhari, fino a che un giorno, " +
            "nell'anno 2971, il fratello di Urhari, Mamellon il corruttore, decise di uscire allo scoperto. " +
            "Egli aveva percepito che Khalindar aveva sviluppato una nuova tecnica per percepire la presenza degli dei oscuri, così da poterli " +
            "individuare e dargli attivamente la caccia, invece di attendere che si facessero loro avanti. Mamellon avrebbe preferito rimanere nascosto per sempre, " +
            "egli infatti odiava il mondo materiale in cui era stato precipitato dopo la sconfitta del padre, il Trementor. " +
            "Per quasi tremila anni aveva atteso nelle profondità del mare, dove si era isolato per dormire. ",
        },
        {
          type: "text",
          value:
            "L'azione di Khalindar però lo disturbò al punto da provocare in lui una reazione. " +
            "Insultato e infastidito da quell'atteggiamento dell'eroe, Mamellon decise di andare incontro al suo avversario. " +
            "Ora Mamellon, a differenza di Urhari, possedeva un potere meno sottile e psicologico, era un dio oscuro più diretto. " +
            "Per sua indole, Mamellon non era uno stratega paziente, non avrebbe speso secoli a pianificare la sua conquista. Avrebbe usato il minimo tempo necessario per consolidare " +
            "le sue forze e poi si sarebbe abbattuto come una tempesta su tutto il continente. " +
            "Egli era in grado di scatenare le forze della natura, poteva convocare interi eserciti di anime dannate ed emanava miasmi che corrompevano la terra e diffondevano piaghe e pestilenze. " +
            "Mamellon non era un dio che lavorava di sotterfugi, ed agiva per lo più con la forza bruta. " +
            "Nel 2973 egli si avvicino alle coste di Fedora e, trovando sul suo passaggio la città di Bolkar (Popolo dei burukhad), " +
            "la distrusse con una piaga che ridusse tutte le persone a un cumulo di cadaveri nel giro di poche ore." +
            "Rimase sorpreso di scoprire che nel mondo non c'era traccia del potere oscuro di Urhari e intuì che il fratello, in qualche modo, " +
            "era stato sconfitto. Questo lo spinse ad agire in modo più discreto. Egli si ritirò in una valle nascosta e iniziò a inviare una rete di spie, " +
            "esseri oscuri suoi servi, al fine di scoprire cosa fosse accaduto al fratello Urhari.",
        },
        {
          type: "text",
          value:
            "Alcuni servi oscuri di Urhari, sopravvissuti agli stermini di purificazione, percependo il ritorno di un potere oscuro maggiore nel mondo, andarono alla ricerca della sua sorgente. " +
            "Mamellon avverti in loro la forza di Urhari e consentì loro di trovarlo e di portargli notizie. " +
            "Nel 2984 Mamellon è messo al corrente di tutto l'accaduto e apprende dell'impero di Urhari e della sua disfatta. " +
            "Nel 2990 decide di spostarsi in segreto verso Masmithra, e decise di stabilirvi la sua forza. " +
            "Non faticò a eliminare la gilda degli incantatori e tutti gli automi Burukhad che la proteggevano. " +
            "In quel luogo, egli scoprì facilmente le sale segrete sigillate da Urhari, a cui solo un dio oscuro avrebbe potuto accedere. " +
            "Li trovò il Necromicron e apprese gli antichi segreti a cui aveva lavorato Urhari, e scoprì che il fratello aveva diseminato il continente di " +
            "tanti culti oscuri. Si compiacque di quella scoperta e decise di usare quei culti per i suoi scopi. ",
        },
        {
          type: "text",
          value:
            "I suoi servi nel frattempo trovarono uno dei più pericolosi servi di Urhari, un re corrotto dai poteri oscuri che fu trasformato " +
            "in Lich durante le grandi guerre contro i popoli dell'ovest. Mamellon, non trovando ancora in vita alleati migliori, si diresse da lui passando per vie nascoste e nel 2995 riesce ad " +
            "allearsi con lui. In Umbri, Mamellon trovò un servo fedele e crudele, e lo rese suo generale di tutte le armate di non morti che aveva raccolto. " +
            "Così, nel 2997 Umbri si muove contro il popolo dei Casadri e dei Severinim, così da aprire un varco verso la fortezza di Ulum, il più prezioso tempio dei sette, che venne saccheggiato. " +
            "Umbri odiava personalmente i sette e Mamellon approvò il suo desiderio di eliminare la traccia della loro gloria. Negli anni fino al 2999, " +
            "La perdita di Ulum non causò la caduta totale del culto poichè negli anni precedenti i sette dei avevano spostato il cuore del loro culto nella cupola nel cielo, " +
            "una strutttura della prima era che orbitava come una luna nel cielo. Conquistata da Khalindar nel 2731 per richiesta della divina Shelmalakia, quella struttura era inizialmente era un osservatorio, ma i sette lo avevano riconvertito nel loro tempio più sacro. " +
            "Umbri e Mamellon seminarono distruzione in rapida sequenza in tutto il continente, distruggendo ogni traccia del culto dei sette sul loro cammino. " +
            "Nel 3006 Mamellon assegna a Umbri un'esercito di dannati di oltre centomila unità e lo invia come una piaga su tutto il continente. " +
            "Nello stesso anno lui si ritira a Masmithra per concentrare le sue forze e prepararsi ad affrontare i campioni della luce di cui aveva tanto sentito parlare. " +
            "Nel 3010 i popoli liberi compresero che il secondo dio oscuro, su cui Khalindar li aveva messi in guardia, era finalmente arrivato. " +
            "Spesero otto anni per prepararsi a un contrattacco, anche perchè rallentati da Umbri che al nord seminava distruzione ovunque passasse. Nel 3018 gli eserciti degli uomini si riunirono per affrontare il nuovo male che si era insediato a Masmithra. " +
            "La guerra contro Mamellon era iniziata. ",
        },
        {
          type: "title",
          value: "La guerra di Mamellon",
        },
        {
          type: "text",
          value:
            "Nel 3020 gli eserciti degli uomini raggiunsero Masmithra, e Mamellon non si fece attendere. " +
            "Dalla fortezza iniziarono a fluire un numero apparentemente illimitato di soldati non morti, e quando la cosa parve non bastare, nel 3021, sgui lo sguinsagliamento dell'esercito " +
            "dei suoi demoni. La battaglia durò cinque anni e fu una delle più cruente della storia di Fedora. " +
            "Infine nel 3026 Khalindar e Opsei ricomparvero e si unirono alla battaglia. " +
            "La loro presenza ribaltò l'esito delle battaglie e Mamellon fu costretto a richiamare il suo generale, Umbri. " +
            "Umbri abbandona le terre del nord e si dirige verso Masmithra per aiutare il suo signore. ",
        },
        {
          type: "text",
          value:
            "Nel 3028, dopo uno scontro che produsse quasi un milione di morti, Khalindar e Opsei, con l'aiuto dei cavalieri della lama d'argento, riuscirono a sconfiggere Umbri e a sigillarlo. " +
            "Ancora più eserciti dei popoli liberi si unirono per combattere il dio oscuro e nel 3030 la lega di tutti i popoli liberi di Fedora riuscì a sconfiggere Mamellon. " +
            "Masmithra fu riconquistata e Mamellon dovette fuggire verso la costa. Li attese con le ultime forze a sua disposizione, causando distruzione gratuita ovunque passasse. " +
            "La terza e ultima battaglia contro Mamellon avvenne nel 3032, e fu una battaglia epica. Mamellon, ormai privo di forze, fu sconfitto e sigillato da Khalindar e Opsei. " +
            "In quella battaglia Opsei fu ferito da un artiglio velenoso di Mamellon e la ferita non guarì mai. " +
            "Nel 3033 Opsei viene accolto nella cupola nel cielo dove la presenza dei sette dei è così forte che riesce a contrastare l'effetto della ferita velenosa di Mamellon, e " +
            "questo gli salva la vita. Si concluse così la guerra con il secondo dio oscuro.",
        },
        {
          type: "title",
          value: "La trappola di Mamellon",
        },
        {
          type: "text",
          value:
            "Nei due anni successivi alla sconfitta di Mamellon, i popoli liberi di Fedora si riunirono per decidere cosa fare dell'ultimo dio oscuro. " +
            "Come tutti ormai già sapevano per rivelazione fornita dai sette, i dei oscuri erano tre. Due erano stati sigillati nel corpo di Khalindar, " +
            "ma i re e i capi dei popoli liberi non sapevano come prepararsi all'arrivo del terzo dio oscuro. " +
            "Mamellon aveva causato decine di milioni di morti in tutto il continente in meno di cinquant'anni. " +
            "Le persone avevano paura, e tutti erano sfiniti. Un altra esperienza come quella di Mamellon, specialmente se fosse avvenuta " +
            "troppo presto, avrebbe condotto i popoli di Fedora all'estinzione.",
        },
        {
          type: "text",
          value:
            "Spinti dalla paura, i re di tutta fedora chiesero con insistenza a Khalindar di individuare subito il terzo dio e sconfiggerlo. " +
            "Alcuni addirittura lo biasimavano per la lentezza con cui si era presentato nella lotta contro Mamellon. " +
            "Khalindar, lavorò per quasi quarant'anni per aiutare a ricostruire i paesi distrutti, ma ovunque andava, tutti " +
            "chiedevano la sua vittoria sul terzo dio oscuro. Alla fine, spinto da quella pressione dei popoli e seguendo il consiglio dei sacerdoti dei sette più influenti, tentò di identificare questo nemico, ma il terzo dio oscuro, Enthial, era molto più astuto dei suoi predecessori e non fu individuato. " +
            "Così, per garantire la sicurezza dei popoli e la possibilità di intervenire rapidamente in caso di necessità, Khalindar chiese " +
            "ai sette il permesso di usare il Mion'Mir per accedere a un potere ancora superiore. " +
            "I sette dei, considerandolo più che degno, gli fornirono le quattro chiavi necessarie per accedere al potere del Mion'Mir. " +
            "Nel 3070 Khalindar usò il Mion'Mir per assumere un potere sovrannaturale, diventando simile a un dio. ",
        },
        {
          type: "text",
          value:
            "Quello che non si aspettò è che il nuovo potere acquisito lo rendeva spiritualmente tanto potente da poter dialogare alla pari persino con i due dei oscuri che abitavano sigillati in lui. " +
            "Per molti mesi i due dei oscuri lo schernirono, lo minacciarono, ma non erano nella posizione di rappresentare una minaccia. " +
            "In particolare Khalindar vide in Mamellon una particolare debolezza e cercò di sfruttarla per scoprire, attraverso la sua comunione con le ombre, dove si nascondeva Enthial. " +
            "Khalindar si immerse nella psiche di Mamellon e scoprì che il terzo dio oscuro si era nascosto in un luogo segreto, una dimensione fittizia creata dalle streghe di Urhari negli anni precedenti la sua caduta. " +
            "Era un passo nella giusta direzione, ma Mamellon lo aveva ingannato. La debolezza che aveva esposto era solo una trappola per spingere Khalindar ad abbassare la guardia e per questo suo errore, " +
            "attraverso il legame che Khalindar stesso aveva cercato, Mamellon potè usare per alcuni secondi il corpo di Khalindar. " +
            "Il tempo non era sufficiente a liberarli dai sigilli, ma Mamellon riuscì a scagliare un'ultima maledizione sul mondo. Usando Khalindar, Mamellon sprigionò una piaga che si spanse tragicamente sul continente. " +
            "Uno stormo di pipistrelli maledetti, portatori di un morbo aggressivo e incurabile pensato per la rovina degli uomini, uscì direttamente dalla bocca dell'eroe. Durò pochi secondi, e duecento pipistrelli lasciarono il luogo in cui si trovava Khalindar. " +
            "Presto questi si sparsero nella natura in ogni direzione e diedero il via ad una piaga che portò all'estinzione di tutti i popoli di Fedora. ",
        },
        {
          type: "text",
          value:
            "Khalindar, resosi conto dell'errore, si ritirò nella sua caverna di meditazione e si sigillò dentro di essa, temendo che Mamellon potesse tentare altri colpi attraverso di lui. " +
            "Da allora, di Khalindar si è persa ogni traccia. Nessuno sa dove si trovi, e molti di quelli che lo conoscevano lo considerarono morto.",
        },
      ],
    },
    {
      title: "Terza era",
      shortDescription: "La terza era di questo mondo",
      content: [
        {
          type: "title",
          value: "Il ripristino della vita",
        },
        {
          type: "text",
          value:
            "La terza era di questo mondo è iniziata negli anni subito successivi l'esilio di Khalindar e la piaga che ha portato all'estinzione di tutti i popoli di Fedora. " +
            "Il mondo, a causa dell'ultimo maleficio di Mamellon, è stato ridotto a un deserto, e la vita umana è scomparsa. " +
            "Gli unici popoli sopravvissuti erano quelli sulle isole, che non avevano subito l'attacco dei pipistrelli maledetti. " +
            "Essendo perfettamente isolati, essi si salvarono e la piaga si estinse da sola prima di poterli raggiungere. " +
            "Lo stesso valse per alcune persone che vivevano in luoghi remoti e inaccessibili. Nei primi anni della terza era, la popolazione di Fedora si era ridotta dell'86% e tutto era andato in rovina. " +
            "Quando la piaga si estinse, i popoli sopravvissuti si armarono e partirono per ricominciare a colonizzare il mondo. " +
            "Durante il primo secolo di questa era, il mondo era un luogo desolato e i popoli che vi abitavano erano sparsi e isolati. " +
            "Molta della cultura della seconda era andò perduta e i popoli che la ricordavano erano pochi. " +
            "La terza era è stata un periodo di ricostruzione e di riscoperta. ",
        },
        {
          type: "text",
          value:
            "Nell'anno 81 della terza era, alcune streghe di Urhari, sopravvissute alle piaghe per via della loro natura non umana, decisero che il tempo era maturo per richiamare Enthial, affinchè, " +
            "secondo il suo stesso piano, potesse impadronirsi del mondo nel momento di sua massima fragilità. Quando Enthial attraversò il portale, le streghe la accolsero con impazienza, e le raccontarono le vicende della seconda era. " +
            "Enthial aveva previsto che dopo la caduta di Urhari, Mamellon avrebbe fatto un disastro nel tentativo di vendicarlo. Però, ai suoi occhi Mamellon non aveva fallito. " +
            "Grazie alle azioni di Mamellon, Enthial aveva molte informazioni su cui basarsi per studiare i campioni della luce. " +
            "Era cruciale per lei comprenderne i segreti e soprattutto, rispettarne la potenza. " +
            "Ne Khalindar ne Opsei erano morti, e ora lei era l'unica a poter completare il piano dei tre dei oscuri di causare del ritorno del Trementor. " +
            "Enthial era la più astuta dei tre fratelli, e sapeva che doveva agire con cautela. " +
            "Le streghe le raccontarono che Khalindar era scomparso e che Opsei era stato visto vagare per il mondo in cerca di risposte, dopo esser guarito dalla ferita di Mamellon che lo tenne nella cupola nel cielo per oltre un secolo. " +
            "Khalindar e Opsei, infatti, per la loro natura semidivina, non potevano morire di vecchiaia.",
        },
        {
          type: "text",
          value:
            "Enthial non fece nulla. Non tentò nulla. Si limitò a osservare per capire come i sette dei della luce avessero avuto un tale successo nel formare due campioni tanto validi. " +
            "Così, Enthial rimase celata e nell'anno 182 decise di spostarsi a Vaniaa per entrare in contatto con i servi di Urhari ancora in forze, protetti nella fortezza di Angramar. " +
            "Quando i servi dell'oscuro Urhari videro Enthial, si spaventarono, ma poi ne furono contenti e la accolsero come una signora e padrona. " +
            "In quel contesto Enthial incontrò anche l'emanazione di Urhari più potente, la macchina, e lei la convinse a servirla. " +
            "Enthial sospettava che i suoi due fratelli avevano perso le loro battaglie perchè avevano cercato di combattere contro l'umanità, e non con essa. " +
            "Così, lei chiese alla macchina di aiutarla a trovare un popolo che lei potesse far suo. ",
        },
        {
          type: "text",
          value:
            "La macchina conosceva Vaniaa alla perfezione e sapeva che nelle isole del nord, nelle terre di Shora, viveva un popolo che per millenni aveva vissuto separato dal resto del mondo e che " +
            "non aveva nulla a che spartire con i popoli di Fedora. Enthial decise di andare a Shora e di sottomettere quel popolo. Nel 184 lei si presentò a Shora e si fece accogliere nelle vesti di una viandante. " +
            "Presto la sua smisurata sapienza e il suo potere trasparirono, e lei venne venerata come una profetessa. Enthial era disgustata dall'umanità, ma sapeva di aver bisogno di loro, quindi recitò la sua parte e " +
            "aiutò i vanardi a prosperare. Rimarrà con loro fino al 191, per poi scomparire improvvisamente. Enthial era impaziente di trovare Khalindar per poterlo studiare e capire come sconfiggerlo. " +
            "Viaggiò sotto copertura in tutto il mondo, fino anche a raggiungere il Gunebar, la terra esotica dell'est. Anche quei popoli, essendo separati da Fedora, non avevano subito la piaga di Mamellon. " +
            "La loro storia era diversa, e la loro cultura era ricca di segreti. Enthial si fermò a Gunebar per anni, in cerca del suo nemico. ",
        },
        {
          type: "text",
          value:
            "Nel frattempo, Opsei aveva viaggiato per anni in cerca del fratello scomparso. Nel 224 rinunciò a trovarlo e volle invece concentrarsi a risanare il mondo. " +
            "Purtroppo però, Fedora era divenuta un agglomerato di popoli barbari in costante conflitto, e non c'era traccia del suo antico splendore. " +
            "Opsei, vedendo che non c'era modo di agire in quel tempo così immaturo, andò nel Gunebar pensando di trovare li una base di civiltà da cui ripartire per ripristinare la vita civile anche in Fedora. Visse li per molti anni, apprendendo la cultura e le lingue delle isole del continente. " +
            "Infine, nel 298, dopo decenni di predicazioni e di insegnamenti, Opsei riuscì a convincere i popoli del Gunebar ad accogliere la fede nei sette dei. " +
            "Intanto in Fedora passò un millennio che servì a ristabilire un minimo di ordine e di civiltà. Le terre si riempirono di nuova vita e le culture diverse iniziarono a prendere forma. " +
            "Nel 1261 Opsei decise di tornare a Fedora, e li trovò un mondo che arrancava per rinascere. Si recò alla fortezza di Ulum, ciò che ne rimaneva, e lavorò per ripristinarla come potè. " +
            "C'erano molti libri antichi e rotoli preziosi in quel luogo. Tanto sapere sui sette dei che lo avrebbe aiutato nella sua opera di riforma della cultura di Fedora. ",
        },
        {
          type: "text",
          value:
            "Negli anni riuscì ad avvicinare vari popoli e ad attrarre persone da tutte le terre attorno a Ulum, trasformandola in una scuola del culto. Nel 1302 fondo ufficialmente la scolastica del culto dei sette, un ordine di sacerdoti che avrebbero dovuto diffondere la fede nei sette dei e ristabilire la pace e l'ordine in Fedora. " +
            "Grazie alla sua sapienza e alla sua millenaria esperienza politica, Opsei riuscì a mediare alleanze e accordi tra molti popoli, e usò questo prestigiò per aiutare la diffusione della fede nei sette. " +
            "Nel 1339 alcuni staterelli di Fedora iniziarono a riconoscere il valore della fede nei sette, e pian piano il numero dei fedeli aumentava. " +
            "Due monaci guerrieri discepoli di Opsei, Dimetreus e Fisir, riconobbero come cruciali i saperi perduti del mondo antico e ne temerono la forza distruttrice. " +
            "Con la benedizione di Opsei, i due fondarono l'ordine dei Collettori nel 1633, un ordine che riuniva molte gilde che avevano in comune il desiderio di preservare la conoscenza antica. " +
            "Per quasi un millennio l'azione dei Collettori fu fondamentale per la riscoperta della cultura e della storia di Fedora. ",
        },
        {
          type: "text",
          value:
            "Intanto Opsei girava per il mondo e aiutava i popoli a rimettersi in sesto, e in molti casi a riconciliarsi. I sette lo sostenevano nello spirito, ma non parteciparono fisicamente alla ricostruzione del mondo. " +
            "Essi avevano deciso di lasciare che gli uomini si riprendessero il loro destino, e che fossero loro a decidere il loro futuro. " +
            "Ciononostante, la loro azione benevola continuò ad aiutare i popoli e a guidarli verso la pace. " +
            "Opsei, che aveva visto la distruzione e la morte, sapeva che la pace era fragile e che la guerra era sempre in agguato. " +
            "Passarono molti altri secoli e lentamente, nacquero i clan, i popoli, i regni e le città. E Opsei, stanco ma mai sconfitto, insisteva a promuovere la vita e la ricostruzione. ",
        },
        {
          type: "title",
          value: "Il segreto del Mion'Mir",
        },
        {
          type: "text",
          value:
            "Per oltre duemila anni, Fedora affrontò una nuova gestazione di tutte le culture che la abitavano. Il Gunebar rimase separato da loro per tutto questo tempo, temendo che la piaga di Mamellon fosse ancora in corso. " +
            "Iniziarono a formarsi i popoli da cui discendono i moderni abitanti di Fedora. Guerre, scontri, politica, rivoluzioni. Fedora cambiò moltissimo in quei secoli, e Opsei, ormai vecchio e stanco, si ritirò a vita privata. " +
            "Nel 3361 nacque un uomo destinato a destabilizzare l'ordine del mondo. Il suo nome era Gallus Alavad Coshellar, e presto sarebbe diventato uno dei più leggendari Collettori della storia. " +
            "Egli trovò nelle sue molte ricerche le tracce della perduta città di Fernia, e successivamente, tracce dell'esistenza di un antico e potentissimo reperto perduto da millenni. Il Mion'Mir. ",
        },
        {
          type: "text",
          value:
            "Le sue scoperte rimasero celate per molti anni, fino a quando quasi ottocento anni dopo, un ragazzo di nome Erujhan non si imbatte nei segreti del suo diario perduto.",
        },
      ],
    },
  ],
};
