const PlayAudio = (audioSource: string) => {
  if (localStorage.getItem("audio") === "false") {
    return;
  }

  var audio: any = new Audio(audioSource);
  audio.play();
};

const AudioUtil = {
  PlayAudio,
};

export default AudioUtil;
