import "./Toolbar.scss";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../AppRoutes";
import { useEffect, useState } from "react";
import AudioUtil from "../../Utils/audioUtil";

export interface IToolbarProps {}

const Toolbar = (props: IToolbarProps) => {
  const navigate = useNavigate();
  const [audio, setAudio] = useState<boolean>(
    localStorage.getItem("audio") !== "false"
  );

  return (
    <div className="toolbar-main-wrap">
      <div
        className="toolbar-logo"
        onClick={() => navigate(AppRoutes.HOME_PAGE)}
      >
        <img src={"/assets/mionmir-logo.png"} />
      </div>
      <div
        style={{ display: "flex", alignItems: "center", gap: "1em" }}
        onClick={() => {
          let audio_ = !audio;
          localStorage.setItem("audio", audio_ ? "true" : "false");
          AudioUtil.PlayAudio("mouseclick.wav");

          setAudio(audio_);
        }}
        className="audio-button"
      >
        <img style={audio ? {} : { opacity: 0.3 }} src="./assets/audio.png" />
        <span>{audio ? "Audio si" : "Audio no"}</span>
      </div>
      <div>
        <button
          className="menu-toggle-button"
          onClick={() => {
            document.dispatchEvent(new Event("menu-toggle"));
          }}
        >
          <img src={"/Assets/menu.png"} />
        </button>
      </div>
    </div>
  );
};

export default Toolbar;
