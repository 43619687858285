import React, { useEffect } from "react";
import LOOP_IMG from "./loopimg.gif";
import LOGO from "./mionmir-logo.png";
import AppLoadingPlaceHolder from "./AppLoadingPlaceHolder";
import AppLayout from "./AppLayout";

const App = () => {
  return (
    <div className="main-font-sizer">
      <AppLayout />
    </div>
  );
};

export default App;
