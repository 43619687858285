import { Route, Routes } from "react-router-dom";
import { AppRoutesMap, IAppRoute } from "./AppRoutes";
import Gallery from "./Components/Gallery/Gallery";
import ImageDisplayer from "./Components/ImageDisplayer/ImageDisplayer";
import ImageDictionary from "./ImageDictionary";
import Navigator from "./Components/Navigator/Navigator";
import Toolbar from "./Components/Toolbar/Toolbar";

const AppLayout = () => {
  return (
    <div>
      <Toolbar />
      <div className="app-layout-base">
        <Navigator />
        <div className="page-main-wrap">
          <Routes>
            {AppRoutesMap.map((x: IAppRoute, i: number) => {
              return <Route key={i} element={x.component} path={x.path} />;
            })}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
