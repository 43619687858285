import { WALL as HISTORY } from "./HistoryTextWalls";
import { WALL as FACTIONS } from "./FactionsTextWall";
import { WALL as RACES } from "./RacesTextWall";
import { WALL as RELIGION } from "./ReligionTextWall";
import { WALL as DEEPER } from "./DeeperLookTextWalls";

export interface IContentBlock {
  type: "title" | "text" | "jsx";
  value: string | JSX.Element;
}

export interface ILoreTextWall {
  title: string;
  shortDescription: string;
  imagePath?: string;
  content?: IContentBlock[];
  sections?: ILoreTextWall[];
}

export const LoreTextWalls: ILoreTextWall[] = [
  { ...HISTORY },
  { ...RACES },
  { ...RELIGION },
  { ...FACTIONS },
  { ...DEEPER },
];
