import "./ArgumentChapter.scss";

export interface ICharacterStats {
  name: string;
  value: string;
}

export interface IArgumentChapterProps {
  index: number;
  content: string;
  imagePath?: string;
  stats: ICharacterStats[];
  quote: string;
}

const colorForScaleValue = (value: string) => {
  switch (value.trim()) {
    case "1":
      return "#c51a1a";
    case "2":
      return "#c5831a";
    case "3":
      return "#c5b01a";
    case "4":
      return "#b3c51a";
    case "5":
      return "#6cc51a";
    case "6":
      return "#48c51a";
    case "7":
      return "#1ac53e";
    case "8":
      return "#1ac586";
    case "9":
      return "#1ac5b3";
    case "10":
      return "#1a91c5";

    case "fuori scala":
      return "url('/Assets/electro.gif')";
    default:
      return "transparent";
  }
};

const labelForScaleValue = (value: string) => {
  switch (value.trim()) {
    case "fuori scala":
      return "ultra";
    default:
      return value;
  }
};

const ArgumentChapter = (props: IArgumentChapterProps) => {
  return (
    <div className="argument-chapter-main-wrap">
      <div
        className="argument-chapter-left-zone"
        style={props.index % 2 === 0 ? { float: "left" } : { float: "right" }}
      >
        <div className="character-info-photo-wrap">
          <div
            className="argument-chapter-image"
            style={{ backgroundImage: `url(${props.imagePath})` }}
          >
            <img
              src={props.imagePath}
              alt="character"
              style={{ width: "100%", height: "100%", opacity: "0" }}
            />
            <div className="argument-chapter-quote-wrap">
              <div
                className="argument-chapter-quote"
                style={props.quote ? {} : { opacity: "0" }}
              >
                <div className="argument-chapter-quote-label">
                  {"«" + props.quote + "»"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="argument-chapter-stat-box-outer">
          <div className="argument-chapter-stat-box">
            <table>
              <tbody>
                {(props.stats || []).map((x: ICharacterStats, i: number) => {
                  return (
                    <tr key={i} className="argument-chapter-stat">
                      <td className="argument-chapter-stat-name">{x.name}</td>
                      {x.value.split("|").map((y: string, j: number) => {
                        return (
                          <td
                            key={j}
                            className="argument-chapter-stat-value"
                            style={{
                              backgroundColor: colorForScaleValue(y),
                            }}
                          >
                            <div className="argument-chapter-stat-value-label">
                              {labelForScaleValue(y).toUpperCase()}
                            </div>
                            {y.includes("fuori scala") && (
                              <img
                                className="fuori-scala-gif"
                                src={"/Assets/electro.gif"}
                                alt="fuori scala"
                              />
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="argument-chapter-right-zone">
        <div className="argument-chapter-content">{props.content}</div>
      </div>
    </div>
  );
};

export default ArgumentChapter;
