import { ILoreTextWall } from "./LoreTextWalls";

export const WALL: ILoreTextWall = {
  title: "Approfondimenti",
  shortDescription: "Dharma, reperti, acherazione, e molto altro!",
  imagePath: "/assets/library.png",
  sections: [
    {
      title: "Dharma e le sue varianti",
      shortDescription:
        "Il Dharma Talgalla, e le tecniche della manipolazione dell'antra",
      content: [
        {
          type: "title",
          value: "Antra, la forza dietro la vita",
        },
        {
          type: "text",
          value:
            "L'antra è un mistero, e nel corso della storia molti studiosi hanno provato a descriverlo in modo completo senza mai riuscirci completamente. " +
            "Questa energia è legata agli esseri vivienti e si manifesta in modo più significativo in esseri complessi come l'uomo, ma è noto che interagisca in generale con le forme di vita in modo strutturato. " +
            "Alcuni la considerano come un'aura della realtà precedente, ossia è la traccia della vita spirituale da cui la realtà materiale è derivata. Altri la considerano la forza vitale che scorre attraverso il mondo. " +
            "Quello che è noto è che l'antra è una forza che può essere manipolata, e risponde nelle persone che lo studiano a sollecitazione bidimienzionali dell'essere. " +
            "In altre parole, risponde a stimoli fisici e spirituali, e la sua manipolazione è una pratica che richiede una profonda comprensione di entrambi gli aspetti dell'essere umano. " +
            "La conoscenza dell'antra come forza insita anche nell'uomo è stata rivelata in origine solo dagli dei a Khalindar e Opsei durante la seconda era, e da allora è stato tramandata attraverso le generazioni attraverso " +
            "le scuole che i due fondarono per il mondo. ",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/antra.png"} />
            </div>
          ),
        },
        {
          type: "title",
          value: "Pentagono di Esse",
        },
        {
          type: "text",
          value:
            "Come l'antra possa essere manipolato da una persona varia a seconda della sua predisposizione. Esistono molte predisposizioni minori, ma nelle scuole di manipolazione dell'antra più potenti, le classi considerate sono solo cinque. " +
            "La classe è determinata alla nascita e permane per tutta la vita. Ogni persona può avere una sola classe, ma esistono rari casi di persone nate in periodi di transizione in cui queste ottenevano due classi invece che una. " +
            "Nei secoli questa casistica è stata studiata a lungo, visto l'enorme vantaggio che comporta, ed è stata raffinata al punto che ora è possibile determinare la classe di una persona con una certa precisione. " +
            "Il merito di questo successo è di Stunkmar Esse, un antico ricercatore e astronomo che nel 1920 della terza era scoprì un legame tra le classi dell'antra e alcune costellazioni. " +
            "Egli lavorò per anni al fine di correggere ogni errore e imprecisione e infine riportò tutto su una mappa che chiamò il Pentagono di Esse. " +
            "Il pentagono permetteva di determinare con precisione superiore al 97% la classe di una persona. " +
            "La sua scoperta fu un evento epocale, e vista la sua importanza, fu deciso dai Collettori di farne un segreto di stato. " +
            "Purtroppo, persone malvage volevano quel tipo di potere e rapirono Esse per ottenerlo. " +
            "Esse, però, non cedette e fu ucciso. Il pentagono rimase negli archivi dei Collettori per molti anni. ",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/essepentagon.png"} />
            </div>
          ),
        },
        {
          type: "text",
          value:
            "Il pentagono sarà poi trafugato da un gruppo di ribelli nel 1938 della terza era, e da allora è andato perduto. " +
            "Il pentagono scomparve per alcuni secoli, poi ricomparve nel sud di Fedora in mano a un clan di guerrieri dell'antra. Scomparve ancora e ricomparve nell'ovest di Fedora, in mano a persone diverse. " +
            "Ogni volta che riappariva, era in un luogo diverso, e ogni volta che veniva trovato, era in un luogo diverso. " +
            "Vari clan si contendevano il reperto e spesso si ucidevano per averlo. " +
            "La sua ultima apparizione risale al 3471 nelle terre di Migulma, dove fu trovato da un gruppo di esploratori di un clan che lo fecero subito sparire come un tesoro. ",
        },
        {
          type: "title",
          value: "Dharma Talgalla - il legame con la natura",
        },
        {
          type: "text",
          value:
            "Il Dharma Talgalla è la più elementare scuola della manipolazione dell'antra che discende direttamente dagli insegnamenti di Khalindar e Opsei. " +
            "Tradotto in lignua corrente significa 'Oscillazione Armonica' e si riferisce all'interazione che l'antra di un utilizzatore di questa scuola deve avere " +
            "con l'antra del mondo circostante al fine di produrre una varietà di fenomeni che si nutrono di questa oscillazione." +
            "Il Dharma Talgalla ragiona sempre per armonie, e spesso le tecniche che lo riguardano sono descritte come veri accordi musicali. " +
            "Il Dharma Talgalla si divide in cinque classi, ognuna delle quali produce effetti diversi. Guardando il pentagono di Esse, partendo dalla punta in alto e andando in senso " +
            "orario le classi sono le seguenti: Zeloth, Kahel, Ellest, Apochoros e Illmaki.",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/darmatalgalla.png"} />
            </div>
          ),
        },
        {
          type: "jsx",
          value: (
            <div>
              <ul>
                <li>
                  Zeloth, simboleggiato dal serpente, è la scuola
                  dell'alterazione. Le persone con questa classe di Dharma
                  possono imparare ad alterare lo stato della materia, arrivando
                  a modificarne le proprietà, la forma e la consistenza. Lo
                  Zeloth è anche la scuola alla base dell'Acherazione, il
                  processo con cui si imprimono circuiti di antra sugli oggetti.
                  L'antra di chi usa questa classe si concentra nel petto.
                </li>
                <li>
                  Kahel, il ragno a dieci zampe, è la scuola dell'esplosione
                  ardente. Le persone con questa classe di Dharma possono
                  imparare a creare onde incendiarie di antra, le quali possono
                  essere usate sia per attacco che per difesa. Gli accordi di
                  Kahel sono molto potenti e possono distruggere interi
                  edificii. L'antra di chi usa questa classe si concentra sulla
                  punta delle dita.
                </li>
                <li>
                  Ellest, il gufo, è la scuola della visione. Le persone con
                  questa classe di Dharma possono imparare a vedere l'antra, e a
                  percepire le armonie che la compongono. Ellest è la scuola
                  alla base della divinazione e della magia di osservazione. Le
                  persone con questa variante di Dharma possono arrivare in
                  alcuni casi a controllare i pensieri delle persone, e nei casi
                  più eccezionali addirittura riescono ad influenzare il moto
                  del destino della storia. L'antra di chi usa questa classe si
                  concentra nel cranio.
                </li>
                <li>
                  Apochoros, il gatto a due code, è la scuola dell'alleanza con
                  il mondo degli spiriti. Chi possiede questa classe di Dharma
                  può imparare a evocare creature dalle dimensioni ultraterrene
                  e a comunicare con loro. L'antra di chi usa questa classe si
                  concentra nei piedi.
                </li>
                <li>
                  Illmaki, il pesce, è la scuola della risonanza. Le persone con
                  questa classe di Dharma possono imparare a proiettare onde di
                  antra che si propagano nello spazio agendo come un sonar. In
                  questo modo ottengono una visuale tattica del mondo
                  circostante anche attraverso le pareti. Chi possiede questa
                  classe è anche in grado di inviare impulsi di antra molto
                  precisi, che può usare come segnali per controllare a distanza
                  oggetti acherati. L'antra di chi usa questa classe si
                  concentra attorno al corpo come una nuvola.
                </li>
              </ul>
            </div>
          ),
        },
        {
          type: "title",
          value: "Dharma Dorchadas - il legame con l'uomo",
        },
        {
          type: "text",
          value:
            "Il Dharma Dorchadas è una scuola di manipolazione dell'antra che si concentra sull'interazione tra l'antra di un individuo e l'antra di un altro individuo. " +
            "Tradotto in lingua corrente significa 'Oscillazione Congiunta' e rappresenta una scuola di livello superiore a quella del Dharma Talgalla. " +
            "L'uso del Dharma Dorchadas permette a due alleati non di sommare le loro forze, ma di moltiplicarle, producendo effetti estremamente più potenti rispetto al Dharma Talgalla. " +
            "Le classi del Dharma Dorchadas sono le stesse del Dharma Talgalla.",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/darmadorchadas.png"} />
            </div>
          ),
        },
        {
          type: "title",
          value: "Dharma Hyuraa - il legame con la bestia",
        },
        {
          type: "text",
          value:
            "Il Dharma Hyuraa è una variante del Dharma Dorchadas inventata da alcuni praticanti del Dharma Dorchadas che hanno pervertito la pratica originale. " +
            "Questa scuola si concentra sull'interazione tra l'antra di un individuo e l'antra della sua parte più animale e recondita. " +
            "Questo tipo di manipolazione è considerata pericolosa e illegale, e chi viene scoperto a praticarla viene giustiziato. " +
            "Le classi del Dharma Hyuraa sono le stesse del Dharma Talgalla, ma gli effetti sono totalmente diversi:",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/darmahyuraa.png"} />
            </div>
          ),
        },
        {
          type: "jsx",
          value: (
            <div>
              <ul>
                <li>
                  Zeloth: questa classe, se pervertita nel Dharma Hyuraa,
                  permette a chi la usa di assorbire antra o addirittura vita da
                  altre creature, e di aggiungerla alla proprie riserva. Questo
                  processo è molto doloroso per la vittima, che spesso muore.
                  L'utilizzatore di questa classe perde la capacità di produrre
                  antra da solo per molti giorni dopo l'utilizzo, e diviene
                  dipendente dall'energia altrui fino a che il suo corpo non si
                  riprende.
                </li>
                <li>
                  Kahel: in questo caso la classe permette di potenziare la
                  propria forma fisica, andando a trasformare il corpo in una
                  macchina da guerra. L'antra diviene un'energia che alimenta lo
                  stato di furia del praticante. Questo stato di furia è molto
                  dannoso per il corpo e a lungo andare porta a generare danni
                  ai tessuti, e a far invecchiare il praticante molto più
                  velocemente.
                </li>
                <li>
                  Ellest: l'ellest è una classe incompatibile con il Dharma
                  Hyuraa, e chi tenta di usarla in questo modo spesso
                  impazzisce, ma nel tempo che precede il suo decadimento
                  graduale, può acquisire il potere di trasmettere imminenza di
                  un grande pericolo e suscitare terrore totale nelle menti
                  degli animali e perfino dei non morti, che sono obbligati a
                  fuggire.
                </li>
                <li>
                  Apochoros: la variante pervertita di questa classe permette a
                  chi la usa di fondersi con una creatura spirituale evocata, e
                  di ottenere i suoi poteri. Questo processo è molto pericoloso
                  e spesso il praticante perde la propria identità fino
                  all'esaurimento dell'evocazione.
                </li>
                <li>
                  Illmaki: in questo caso, la classe permette di creare uno
                  stato di ultra istinto che permette a chi lo usa di
                  moltiplicare i suoi sensi in modo innaturale. Ad esempio, si
                  guadagna la capacità di vedere dietro gli angoli, o al buio, o
                  di sentire i battiti del cuore di una persona a chilometri di
                  distanza. Questo stato di ultra istinto può risultare
                  ingovernabile e portare alla morte del praticante.
                </li>
              </ul>
            </div>
          ),
        },
        {
          type: "title",
          value: "Dharma Endelior - il legame con il divino",
        },
        {
          type: "text",
          value:
            "Il Dharma Endelior è la forma più avanzata di manipolazione dell'antra, e si concentra sull'interazione tra l'antra di un individuo e l'antra di un dio. " +
            "Khalindar e Opsei insegnarono questa scuola solo a pochi eletti, e la sua pratica è vista come il privilegio dei campioni della luce. " +
            "Il Dharma Endelior si alimenta dell'antra del divino, il quale per essere accumulato deve essere ottenuto tramite una relazione di profonda devozione e servizio al dio. " +
            "Il metodo più tipico, ma meno efficace, è la preghiera, ma esistono metodi più diretti come il dialogo durante un'apparizione di un dio. " +
            "Le classi del Dharma Endelior sono le stesse del Dharma Talgalla, e gli effetti sono superiori anche a quelli del Dharma Dorchadas. ",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/darmaendelior.png"} />
            </div>
          ),
        },
      ],
    },
    {
      title: "Reperti e Reliquie",
      shortDescription: "Leggende e classificazione dei reperti antichi",
      content: [
        {
          type: "title",
          value: "Le classi dei reperti",
        },
        {
          type: "text",
          value:
            "I reperti sono tutti quegli oggetti che vengono dal passato, spesso dalla seconda era e molto più raramente dalla prima. " +
            "L'interesse verso di essi non è puramente culturale o storico. " +
            "Spesso i reperti infatti sono legati a particolari eventi o persone, a volte rivelano segreti perduti di grande valore, e possono avere proprietà acherate o misteriose. " +
            "I reperti vengono classificati in base alla loro origine e alla loro funzione. Le classi sono cinque in tutto, dalla classe A fino alla D: ",
        },
        {
          type: "jsx",
          value: (
            <div>
              <ul>
                <li>
                  Classe A: i reperti leggendari dei primogeniti. Sono
                  considerati inestimabili e spesso sono legati a tecniche di
                  costruzione che sono un mistero anche per gli scenziati dei
                  tempi moderni. Questi reperti sono più unici che rari, e il
                  loro ritrovamento è considerato un evento epocale.
                </li>
                <li>
                  Classe B: i reperti creati dai primogeniti, sia acherati che
                  non acherati. Tutto ciò che rientra nel dominio della prima
                  era è classe B.
                </li>
                <li>
                  Classe B-Traccia: solitamente importanti reliquie create dai
                  secondo geniti che però sono cruciali per il ritrovamento di
                  reperti di classe B o superiore.
                </li>
                <li>
                  Classe C: i reperti creati dai secondo geniti di valore
                  storico o artistico superiore. Si parla di oggetti appartenuti
                  a casate nobili o che erano usati in cerimonie religiose.
                  Rientrano in questa classe anche tutti i reperti di classe D
                  che siano stati acherati.
                </li>
                <li>
                  Classe D: i reperti creati dai secondo geniti con valore
                  puramente storico e culturale. Antichi utensili, monili,
                  vestiario, vasellame, tele, testi rati, etc...
                </li>
              </ul>
            </div>
          ),
        },
        {
          type: "title",
          value: "La leggenda del Varnath Ankacl Doizen",
        },
        {
          type: "text",
          value:
            "Nel 1187 della seconda era, durante il periodo in cui Urhari era ancora al potere nella terra oscura di Quasdora, viveva un re di un popolo che " +
            "confinava direttamente con Quasdora. Il re era chiamato Osmadneio, ed egli era in preda a continui incubi per il timore di un possibile attacco del dio oscuro. " +
            "A differenza di lui, la moglie Collonia, donna avida e ambiziosa, vedeva nella vicinanza delle terre di Quasdora un'opportunità. " +
            "Inizialmente scettico e spinto dalle avide ambizioni della moglie Collonia, il re Osmadneio decise di collaborare con alcuni cortigiani che erano in contatto con dei servi di Urhari nascosti nella sua città. " +
            "Quei servi stavano attendendo un'occasione come quella per farsi un alleato oltre i domini del sud, così da poterlo spingere a combattere contro gli altri popoli liberi al momento opportuno. " +
            "I servi di Urhari offrirono in promessa qualunque desiderio in cambio della sua silenziosa fedeltà e del suo aiuto. Il re fu manipolato da sua moglie e spinto dalla sua insistenza, così infine accettò. " +
            "Il re Osmadneio non era però uno sciocco, sapeva di non potersi fidare di qualche oscuro sortilegio, così chiese invece di avere i cinque oggetti che sapeva essere in possesso ai più pericolosi servi di Urhari. " +
            "Urhari, sapendo che i popoli liberi si stavano organizzando per attaccarlo, si trovò costretto ad accettare. " +
            "Dalla terra di Quasdora vennero perciò portati i cinque reperti. Erano reliquie della prima era che Urhari stesso aveva sottratto alla tesoreria del re di Amrath durante la prima era, prima del crollo della loro civiltà. " +
            "Egli non intendeva separarsene per sempre, e sperava di poterseli riprendere da Osmadneio una volta risolta la questione della guerra sul suo confine.",
        },
        {
          type: "text",
          value:
            "Così, il re Osmadneio si alleò con Urhari. Aprì per lui una rotta commerciale fittizia che permetteva alle spie di Urhari di infiltrarsi nei territori del nord. " +
            "Quando nel 1241 i popoli liberi attaccarono Quasdora, lui uscì allo scoperto e combattè con i suoi eserciti al fianco di Urhari. " +
            "Essendo stata smascherata la sua alleanza con il dio oscuro, il re Osmadneio non ebbe più motivo di limitare le sue azioni e il suo dominio sulle terre circostanti prese ad espandersi, e il suo potere crebbe. " +
            "Morì nel 1262, senza eredi, e i suoi cinque reperti vennero rubati da alcuni della sua corte e condotti lontano, prima che Urhari potesse riprenderseli. " +
            "I cinque reperti sparirono per molti secoli fino a quando non riapparvero in varie parti del mondo in mano a diversi nobili di alcune casate. " +
            "Infine i reperti furono recuperati dai cavalieri della lama d'argento nel 2451 e condotti tutti in cinque luoghi di contenimento dove i reperti sarebbero rimasti in custodia al loro ordine. " +
            "In quei luoghi sarebbe dovuto vivere almeno un cavaliere che fosse anche consacrato come sacerdote, così che potesse con la sua azione scongiurare la tentazione che i reperti causavano nelle persone. " +
            "I luoghi di contenimento erano stati costruiti appositamente per quello scopo in luoghi nascosti e l'unico modo di trovarli era completare cinque diversi percorsi " +
            "di pellegrinaggio che si snodavano per il mondo. Persino tra i cavalieri della lama d'argento, solo pochi membri conoscevano le posizioni dei vari luoghi di contenimento. " +
            "I primi sacerdoti che si occuparono dei gioielli di Osmadneio diedero anche il nome ai reperti stessi, così divennero: La voce di Tharminòs, l’occhio di Tuzdokai, il tocco di Kharn, l’udito di Annassé e la mente di Leroran.",
        },
        {
          type: "text",
          value:
            "Dopo quattro secoli, il ricordo dei cinque gioielli andò perdendosi nel mondo, e i cavalieri della lama d'argento " +
            "si sentirono più sicuri nel rivelare la posizione dei luoghi di contenimento. Erano convinti che solo i loro pellegrini più fedeli e gli altri cavalieri li avrebbero raggiunti. " +
            "Così, scelsero una città che era legata al culto dei sette e a Khalindar come punto di partenza per i pellegrinaggi. Scelsero Imil, la città nella montagna, e vi costruirono una mappa. La città era per metà nascosta nell'incavo di un'enorme caverna che la copriva anche da sopra, e parte del " +
            "tetto era sostenuto da un'enorme colonna in avorio e marmo. Su quella colonna, i cavalieri incisero la mappa delle cinque vie per i pellegrini che volessero trovare i luoghi di contenimento, ormai considerati solo luoghi di culto. " +
            "Chiamarono la colonna Varnath Ankacl Doizen: il piedistallo della via per la comprensione. " +
            "Il piedistallo è stato in uso solo per un paio di secoli come un luogo di partenza per i fedeli della lama d'argento, fino a quando per un cataclisma non finì sommerso assieme la città. " +
            "Da allora, il piedistallo è stato dimenticato, e la mappa incisa su di esso è stata persa. ",
        },
        {
          type: "title",
          value: "L'armatura di re Hikhaard",
        },
        {
          type: "text",
          value:
            "Il re Hikaard di Amrath, negli anni del suo massimo splendore, aveva condotto molte guerre al fine di difendere la gloria del suo popolo contro tutti gli assalti dei regni circostanti. " +
            "Egli poteva contare sulla tecnica avanzata del suo popolo per la costruzione di armature e armi, e aveva a disposizione un arsenale di armi acherate che nessun altro regno poteva vantare. " +
            "Grazie al lavoro del dio Arzik in persona, re Hikaard ottenne un'armatura che lo rendeva invincibile in battaglia. " +
            "L'armatura si costituiva di sei pezzi: Il busto, i gambali, i guanti, l'elmo, la spada e il pugnale. " +
            "Ogni pezzo era acherato con una tecnica diversa, e aveva un effetto diverso, e quando il re la indossava, perfino un dio oscuro avrebbe avuto timore a sfidarlo. ",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/hikaard.png"} />
            </div>
          ),
        },
        {
          type: "text",
          value:
            "Dopo la caduta di Amrath i pezzi della sua armatura andarono dispersi e per secoli nessuno li vide più. " +
            "Durante tutta la seconda era, solo tre pezzi dell'armatura vennero rinvenuti, i gambali, la spada e il pugnale. Questi furono custoditi gelosamente dalle casate che li possedevano, " +
            "e in momenti di conflitto li esibivano per scatenarne i tremendi poteri. " +
            "L'armatura di Hikaard era da tutti considerato il set di reperti più potente che esistesse, e questo faceva si che chiunque la " +
            "possedesse, anche se solo in parte, finiva nel mirino di molti nemici.",
        },
        {
          type: "text",
          value:
            "Durante la terza era, anche di questi tre reperti si perse traccia. Uno di questi, il pugnale, fu visto in possesso del Collettore Coshellar durante la sua vita, prima di sparire nuovamente dopo che egli mori. " +
            "Il mistero aleggia attorno a questi reperti tanto ambiti e tanto pericolosi, e chiunque ne trovi anche solo una componente è destinato a entrare nella leggenda. ",
        },
        {
          type: "jsx",
          value: (
            <div>
              <ul>
                <li>
                  L'Elmo del re: questo componente dell'armatura ha un potere
                  che pochi altri reperti riescono a imitare, e nessuno con la
                  stessa efficacia. Chi indossa l'elmo di Hikaard recupera le
                  forze della sua gioventù, e letteralmente inizia un processo
                  di ringiovanimento che si stabilizza nell'età di massimo
                  vigore della persona. Il reperto può avere un effetto
                  complessivo di prolungamento della vita di duecento anni per
                  ogni diverso utilizzatore.
                </li>
                <li>
                  Il busto del re: questo componente consente di creare attorno
                  a chi lo indossa una barriera d'antra impenetrabile a
                  qualunque attacco.
                </li>
                <li>
                  I gambali del re: chi indossa questi gambali può correre e
                  combattere senza mai esaurire le proprie energie fisiche.
                </li>
                <li>
                  I guanti del re: l'uso di questi guanti consente due azioni
                  distinte. Il guanto destro consente di scagliare colpi d'aria
                  con il pugno in grado di colpire con la forza di un treno in
                  corsa. Il sinistro invece consente di utilizzare la spada del
                  re senza subirne gli effetti negativi.
                </li>
                <li>
                  La spada del re, Abrasil: la spada che è in grado di fendere
                  attraverso qualunque armatura o ostacolo fisico, e colpure
                  direttamente gli avversari nei punti in cui lo spirito si lega
                  al corpo. Abrasil è una lama in grado di danneggiare i canali
                  d'antra di una persona, e se usata in modo chirurgico può
                  letteralmente staccare le persone dal loro corpo. Se
                  utilizzata senza il guanto sinistro del re, però, la spada ha
                  un effetto collaterale che danneggia anche chi la impugna
                  avvelenandogli il sangue. Questo per evitare che qualcuno
                  raccogliesse la spada del re durante uno scontro e tentasse di
                  usarla contro di lui.
                </li>
                <li>
                  Il pugnale del re: il pugnale di Hikaar è un oggetto che è
                  stato aggiunto in un secondo momento al suo arsenale. Arzik,
                  quando la creò, lo fece in vista di un avversario del calibro
                  di un dio. Sperava che fosse l'arma decisiva da usare contro i
                  suoi fratelli oscuri. Il pugnale, quando infilzato in un
                  corpo, ha il potere di annullare tutto l'afflusso d'antra che
                  il corpo può erogare verso il mondo circostante. In altre
                  parole, qualunque avversario che usasse in qualunque modo
                  l'antra per combattere si sarebbe trovato totalmente
                  impotente.
                </li>
              </ul>
            </div>
          ),
        },
        {
          type: "title",
          value: "La cupola nel cielo, Zhurum",
        },
        {
          type: "text",
          value:
            "L'enorme struttura fluttuante nella bassa atmosfera di forma sferica è stata al centro di molte leggende. Fu creata dai primogeniti come osservatorio e centro di ricerca per lo studio " +
            "dell'universo e come centro scientifico d'elité, durante gli anni di gloria di Amrath. Creata con la tecnica dell'acherazione unita all'energia eluka, la cupola era in grado di volare placidamente attorno al pianeta e di mantenere una quota fissa. " +
            "Vista la sua peculiare posizione, questa è stata l'unica struttura dei primogeniti non coinvolta dalla distruzione che distrusse il loro popolo quando il Mion'Mir lo maledì. " +
            "Sopravvissuta intatta alla prima era, durante la seconda è stata ignorata per molti secoli. Alcuni studiosi del cielo inizialmente l'avevano scambiata per una stella, poi per un pianeta. Infine alcuni si erano resi conto " +
            "che la cupola orbitava si attorno al pianeta, ma a una quota molto bassa. ",
        },
        {
          type: "jsx",
          value: (
            <div className="generic-lore-image">
              <img src={"/assets/zhurum.png"} />
            </div>
          ),
        },
        {
          type: "text",
          value:
            "Nel 2731 della seconda era la cupola fu riconquistata da Khalindar per richiesta della divina Shelmalakia, e con l'ausilio dei sette venne " +
            "consacrata a nuovo tempio sacro del culto dei sette. La cupola fu ribattezzata Zhurum, che significa 'luce del sapere'. " +
            "Da allora, la cupola è stata il centro di studi e ricerche per il culto dei sette, e vi si sono svolte molte cerimonie e riti. " +
            "La cupola divenne il luogo in cui andavano a riposare i sette dei, e dove si incontravano quando occorreva loro un luogo sicuro e privo di spie. " +
            "Questo continuò fino alla terza era, fino a quando Opsei nel 1287, dopo aver riparato il tempio di Ulum, Opsei andò alla conquista della cupola nel cielo dove pensava che avrebbe trovato " +
            "i sette dei in concilio. Essi non erano li, ma il luogo era ancora sacro e carico della loro presenza, così Opsei decise di farne il rifugio dei sette sommi sacerdoti del culto che aveva intenzione di fondare. " +
            "Nell'anno 1341 ascesero nella cupola i primi sette sommi sacerdoti, condotti in cielo tramite il sapere di Opsei che sapeva come governare la piattaforma d'ascenzione che dalla cupola scendeva sino a terra. " +
            "Opsei insegnò ai sette sommi sacerdoti tutto quello che sapeva sulla cupola, quindi li lasciò alla loro vita di meditazione e preghiera. ",
        },
        {
          type: "text",
          value:
            "Dalla cupola, i sette sommi sacerdoti, tramite un cerchio di dialogo telepatico, informavano i loro vicari in terra delle decisioni che prendevano. Il culto dei sette " +
            "divenne temuto e rispettato e il rituale di selezione di un nuovo sommo sacerdote presto divenne una vera festa religiosa. " +
            "Ogni sommo sacerdote, grazie all'aura benefica della cupola, era in grado di vivere fino a quattrocento anni, e la sua saggezza era tale che i re e i signori del tempo " +
            "andavano a consultarli per ogni decisione importante tramite gli appositi cerchi telepatici sparsi nelle più importanti Sinjid nel mondo. ",
        },
        {
          type: "text",
          value:
            "Presto la cupola iniziò ad attirare anche l'attenzione di molti ladri e di molti nemici del culto dei sette. " +
            "Questo perchè ad ogni rituale di ascenzione, il nuovo sommo sacerdote che saliva al cielo soleva condurre con se numerosi tesori e reliquie del culto. " +
            "Durante il rituale era impossibile rubarli per via della sicurezza presente sul luogo, ma tutti sapevano che la cupola era un'enorme tesoreria volante. " +
            "Essa però, era anche inavvicinabile. Khalindar, durante la seconda era, quando la conquisto per i sette dei, usò il Dharma Endelior per creare dei sigilli di protezione attorno ad essa. " +
            "La cupola è protetta da due diverse barriere di energia invisibile che costantemente la isolano dal mondo esterno, e un sistema di difese automatiche che la proteggono da attacchi esterni. " +
            "I vari tentativi di assalto avvenuti nella storia della terza era si sono tutti conclusi con drammatiche conseguenze per gli attaccanti. ",
        },
      ],
    },
    {
      title: "Acherazione",
      shortDescription: "L'arte di imprimere circuiti di antra sugli oggetti",
      content: [
        {
          type: "title",
          value: "L'origine dell'acherazione",
        },
        {
          type: "text",
          value:
            "L'acherazione è la pratica di imprimere circuiti di antra sugli oggetti. Nasce nella prima era come tecnica avanzata di programmazione dei circuiti d'antra, e viene utilizzata la prima volta per " +
            "costruire il Mion'Mir, e nei tempi successivi viene estesa a molti altri oggetti. " +
            "Perduta dopo la caduta dei primogeniti, viene ripresa durante la seconda era grazie agli insegnamenti di Khalindar, che amava particolarmente quell'arte e l'aveva studiata a lungo. " +
            "Quest'arte richiedeva una predisposizione al Dharma Talgalla di classe Zeloth, e per questo motivo era praticata solo dai pochi individui compatibili. " +
            "Grazie all'acherazione, gli oggetti potevano essere modificati in modo permanente, e acquisivano proprietà simil-magiche. " +
            "Un'oggetto acherato era alimentato dall'antra dell'utilizzatore come fosse lui stesso una batteria, e in cambio l'oggetto sprigionava la sua energia in modo controllato con effetti programmati. " +
            "Alcune varianti più complesse facevano uso di alcuni particolari cristalli che possono accumulare antra per funzionare senza dipendere da un utilizzatore, come furono appunto gli automi Burukhad, i soldati meccanici di quel popolo. ",
        },
        {
          type: "text",
          value:
            "Con il tempo, nacque l'esigenza commerciale di aumentare la produzione di oggetti acherati, e per questo motivo furono fondate le tavole di Behina, " +
            "oggetti che permettevano di acherare oggetti in modo automatico. Ciò voleva dire che la presenza della classe Zeloth non era più richiesta, però le tavole " +
            "avevano il limite di poter applicare un unico tipo di acherazione che era sempre uguale. Questi oggetti venivano prodotto in modo artigianale secondo metodi " +
            "di acherazione classica uniti a tecniche di sigilli d'antra. Erano molto difficili da realizzare e per questo erano molto costosi. ",
        },
        {
          type: "text",
          value:
            "Con il passare delle ere, la pratica dell'acherazione si è persa assieme al Dharma Talgalla, e ora è considerata una pratica arcaica e superata. " +
            "Nonostante ciò, nel Gunebar, la gilda degli artigiani continua a custodire le antiche tavole di Behina e a produrre oggetti acherati, anche se in numero molto ridotto. " +
            "Alcuni di loro, quando venivano scoperti ad appartenere alla classe dello Zeloth, venivano messi a praticare il Dharma Talgalla e questi studiano tutta la vita per imparare a creare nuove tavole, " +
            "ma nessuno è mai riuscito a farlo. ",
        },
      ],
    },
    {
      title: "Spade Yorka",
      shortDescription: "Le lame acherate dei guerrieri della seconda era",
      content: [
        {
          type: "title",
          value: "Breve storia delle spade Yorka",
        },
        {
          type: "text",
          value:
            "Con l'avvento dell'acherazione, non passò molto prima che qualcuno pensò di applicarla alle armi. " +
            "Così, nacquero le spade Yorka, le prime armi acherate della storia. " +
            "Queste tavole prendevano il nome dalla città dove viveva la gilda dei fabbri di pugno martello, i migliori fabbri dell'epoca. " +
            "Le spade Yorka variavano in forma e dimenzione, ma tutte avevano in comune il fatto di essere acherate, alcune con effetti semplici, altre con effetti devastanti. " +
            "La pratica di costruire le spade di Yorka si perse con la fine della seconda era, e ora sono considerate reperti archeologici di grande valore. ",
        },
        {
          type: "title",
          value: "Le classi delle spade Yorka",
        },
        {
          type: "text",
          value:
            "Le spade Yorka si dividono in tre categorie: quelle a lama incantata, le spade del comando e quelle di rinforzo passivo. " +
            "Le spade a lama incantata sono le più comuni, e sono spade che possiedono una varietà di effetti, dal potenziamento della lama, alla capacità di infliggere danni elementali, " +
            "al potere di tornare in mano all'utilizzatore se lanciate. Poi ci sono le spade del comando, che danno all'utilizzatore la capacità di aumentare le prestazioni belliche dei suoi " +
            "soldati, o addirittura guarirli se venivano feriti. Infine ci sono le spade di rinforzo passivo, queste appaiono come lame comuni, ma permettono all'utilizzatore di ottenere dei bonus di resistenza, destrezza o atleticità. ",
        },
      ],
    },
  ],
};
